import React from 'react'
import { connect } from "react-redux";
import { faPenSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'
import {URL} from  '../../../../Constants/API'
import Modal from '../../../../Components/Modal'
class DestrezasSubCard extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          active: (this.props.data.ID)? false : true,
          control: false,
          actualidad: false,
          loader: false,
          destreza: this.props.data,
          show: false
        };
    }
    componentWillReceiveProps(){
        this.setState((state, props) => ({
            destreza: props.data
          }));
        this.setState((state, props) => ({
            active: (props.data.ID)? false : true
        }));
    }
    handleClick(e){
        e.preventDefault();
        this.setState({
            active: !this.state.active
        })
       
        
    }
    async handleSave(e,i){
        this.setState({loader:true})
        e.preventDefault()
        await this.formControl()
        if(this.state.control){
            if (this.state.destreza.ID) {
                /**Hago el PUT en caso de que el ID ya exista */
                let config ={
                    method: "PUT", 
                    body: JSON.stringify(this.state.destreza), 
                    headers:{'Content-Type': 'application/json'} }
                fetch(URL + "curriculums/habilidades/id/"+this.state.destreza.ID, config )
                .then(res => res.json())
                .then(response => {
                    this.setState({active: false})
                    this.setState({loader:false})
                })
                
            }else{
                /**Hago el post en caso de que el ID no exista */
                console.log("POST")
                let config ={
                    method: "POST", 
                    body: JSON.stringify(this.state.destreza), 
                    headers:{'Content-Type': 'application/json'} }
                fetch(URL + "curriculums/habilidades", config )
                .then(res => res.json())
                .then(response => {
                    console.log(response)
                    this.setState({
                        destreza: {...this.state.destreza, ID : response.data.ID }
                    })
                    this.setState({active: false})
                    this.setState({loader:false})
                })
                
            }
            
            
        }else{
            this.setState({show: true})
            this.setState({loader:false})
        }
        
    }
    handleDelete(e){
        this.setState({loader:true})
        e.preventDefault()
        /**Elimino el destreza */
        let config ={ 
            method: "PATCH", body: JSON.stringify({estado: "BAJA"}), 
            headers:{'Content-Type': 'application/json'}}
        /**Funcion para actualizar */
        const update = ()=>{
            let configGet = { 
                method: "GET", 
                headers:{
                    'Content-Type': 'application/json'}}
            fetch(URL+"curriculums/habilidades/curriculumID/"+this.props.sesion.curriculumID, configGet)
            .then(res => res.json())
            .then(response =>{
                console.log(response)
                let rows = (response.code <400) ? response.data.rows : []
                this.props.update(rows)
                
            })
            .catch(error =>{
                this.setState({loader:false})
                console.error(error)
            }) 

        }
        /**Si el destreza está guardado lo elimino del server  */
        if(this.state.destreza.ID){
            fetch(URL + "curriculums/habilidades/estado/id/"+this.state.destreza.ID, config )
            .then(res => res.json())
            .then(response => {
                this.setState({loader:false})
                update()  
            })
            .catch(Error => this.setState({loader:false}))
        }else{
            /**Si el destreza no está guardado, actualizo redux */
            update()
        }
        

        /**/  
    }
    
    
   
    handleInputChange (e, i) {
        
       this.setState({
           destreza: {...this.state.destreza,[e.target.name] : e.target.value}
       })
    }
    handleInputChangeCKEditor(data, i){
        this.setState({
            destreza: {...this.state.destreza,descripcion : data}
        })
        
     } 

    
    
    handleClassName (){
        let clase = "border  px-5 py-5 mb-5 level experienceSubCard"
        let hidden = "is-hidden"
        let retorno
        if (!this.state.active){
            retorno = clase
        }else{
            retorno = hidden
        }
        return retorno
    }
    isEmpty(e){
        if(!e.value){
            e.className = "input is-danger"
            this.setState({control: false})
        }else{
            e.className = "input is-success"
        }
    }
    formControl(){
        this.setState({control: true})
        this.isEmpty(document.getElementById("DestrezasSubCard__nombre--"+this.props.index))
        this.isEmpty(document.getElementById("DestrezasSubCard__nivel--"+this.props.index))
    }
    getNivel(number){
        switch(number){
            case "5" :{
                return "Experto"
            } 
            case "4" :{
                return "Avanzado"
            } 
            case "3" :{
                return "Medio"
            } 
            case "2" :{
                return "Basico"
            } 
            case "1" :{
                return "Principiante"
            } 
            default:{
                return "Principiante"
            }
        }
    
    }
    render(){
        return(
            <>
            <div  className={this.handleClassName()} >
                <div className="level-left">
                    <div className="level-item">
                        <p>{this.state.destreza.nombre} - {this.getNivel(this.state.destreza.nivel)} </p>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                         <FontAwesomeIcon className="is-medium edit color-success" icon={faPenSquare} onClick={(e)=>{this.handleClick(e)}} />
                    </div>
                    <div className="level-item">
                        <button className="delete has-text-warning is-medium" aria-label="delete" onClick={(e)=>this.handleDelete(e)} > </button>
                        
                    </div>
                    
                    
                </div>
                
                
            </div>
            <div className={this.state.active ? "mb-5  border px-5 py-5": "is-hidden"} >
            <div className="columns is-multiline">
            <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                Información de destrezas
                            </p>
                        </header>
                    <div className="card-content columns is-multiline">
                        <div className="field column is-full">
                            <label className="label">Habilidad<span>*</span></label>
                            <div className="control">
                                <input className="input" value={this.state.destreza.nombre} id={"DestrezasSubCard__nombre--"+this.props.index}  type="text" name="nombre" onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>{this.handleInputChange(e, this.props.index)}} placeholder="P. ej.: Soldador acero inoxidable / Gestor comercial / Análisis de costo / Etc."/>
                            </div>
                        </div>
                        <div className="field column is-full">
                            <label className="label">Elegir nivel<span>*</span></label>
                            <div className="select">
                                <select name="nivel" value={this.state.destreza.nivel} id={"DestrezasSubCard__nivel--"+this.props.index} onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>{this.handleInputChange(e, this.props.index)}}>
                                    <option value="5">Experto</option>
                                    <option value="4">Avanzado</option>
                                    <option value="3">Medio</option>
                                    <option value="2">Básico</option>
                                    <option value="1">Principiante</option>
                                </select>
                            </div>
                        
                        </div>
                        </div>
                        <footer className="card-footer px-5 py-5">
                        { (this.state.loader) ?
                        <CircularProgress color="secondary" />
                        :  <><button className="button is-success mr-3" onClick={(e)=>this.handleSave(e, this.props.index)} > Guardar</button>
                        <button className="button is-danger" onClick={(e)=>this.handleDelete(e)} >Eliminar </button>
                            </>}
                            
                        </footer>
                        </div>
                    </div>
               
            </div>
    
            <Modal show={this.state.show} handleClose={()=>this.setState({show: false})}>
                <article className="message is-danger">
                    <div className="message-body ">
                        Error - no se completó la información correctamente
                    </div>
                </article>
            </Modal>
            </>
        )
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion,
    destrezas: state.Destrezas
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
      dispatch({
        type: "UPDATE_DESTREZAS",
        data
      })
    },
  })
export default connect(mapStateToProps,mapDispatchToProps)(DestrezasSubCard) 