import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import '../../../Sass/responsePostulaciones.scss'

function ResponsePostulacion({estado}){
    
    return(
        {
            'success':
                <>
                <h1 style={{'textAlign':'center'}}>¡Completaste tu postulación!</h1>
                <h2 style={{'textAlign':'center'}}>Te contactaremos con info acerca del puesto de trabajo</h2>
                <div className='buttons-container'>
                    <Link to="/"><Button variant='outlined'>Volver al inicio</Button></Link>
                    <Link to="/avisos"><Button variant='outlined'>Ver más avisos</Button></Link>
                </div>
                </>,

            'duplicate_email':
                <>
                <h1 style={{'textAlign':'center'}}>Tienes una postulación anterior a este aviso </h1>
                <h2 style={{'textAlign':'center'}}>Al parecer ya te has postulado a este puesto</h2>
                <div className='buttons-container'>
                    <Link to="/"><Button variant='outlined'>Volver al inicio</Button></Link>
                    <Link to="/avisos"><Button variant='outlined'>Ver más avisos</Button></Link>
                </div>
                </>,
        }[estado]
    )
}

export {ResponsePostulacion}