import React from 'react'
import ReactDOM from 'react-dom'
import Button from '@material-ui/core/Button';
import { connect } from "react-redux"
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link, Redirect} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import {URL} from '../../Constants/API'
import Modal from '../../Components/Modal'
import CargaArchivos from '../../Components/CargaArchivos'
import ArchivoCard from './ArchivoCard';
import {DropzoneArea} from 'material-ui-dropzone'
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
class Archivos extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            archivos: [],
            control:false,
            redirect: false,
            loader: false,
            loader_cvs: true,
            show: false,
            nombreArchivo:'',
            files: []
        };
    }
    componentDidMount() {
        let config = { 
            method: "GET", 
            headers:{
                'Content-Type': 'application/json'}
        }
        fetch(URL+"files/personaID/"+this.props.sesion.personaID, config)
        .then(res => res.json())
        .then(response =>{
            console.log("Archivos: ", response )
            if(response.code>=400){
                this.setState({archivos: []})
            }else{
                this.setState({archivos: response.data})
            }
           
            this.setState({loader_cvs: false})
            console.log(this.state.archivos)

        })
        .catch(error => console.error(error))

    }
    redirect(){
        this.props.update({...this.props.sesion, curriculumID: null})
        this.setState({loader: true})
        this.setState({redirect: true})
    }

    getFiles(files){
        this.setState({ file: files[0] })
        console.log(this.state.file)
    }

    async guardarArchivo(){
        await this.setState({control: true})
        this.isEmpty(document.getElementById("nombre-archivo"))

        if(this.state.control){
            this.setState({nombreArchivo:document.getElementById("nombre-archivo").value})
            
            let data = {
                titulo: this.state.nombreArchivo,
                file: this.state.file,
                personaID: this.props.sesion.personaID
            }
            console.log('data archivos',data)
            this.post(data)
        }
    }

    isEmpty = (e)=>{
        if(!e.value){
            e.className = "input is-danger"
            this.setState({control: false})
        }else{
            e.className = "input is-success"
        }
        
    }

    async post(data){
        var formData = new FormData()
        formData.append("titulo",data.titulo)
        formData.append("file",data.file)
        formData.append("personaID",data.personaID)
        console.log('post archivos',formData.get('file'))
        /**Guardo información de perfil*/
        fetch(URL + "files", { method: "POST", body: formData})
        .then(res =>res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {    
            this.setState({loader:false})
            if(response.code < 400){
                console.log(response)
                let fecha = new Date()
                let fechaFormateada = fecha.getDate()+'/'+fecha.getMonth()+1+'/'+fecha.getFullYear()
                let nuevoArchivo = {
                    archivo: response.data.archivo,
                    titulo: response.data.archivo,
                    ID: response.data.ID,
                    createdAt: fechaFormateada,
                    path: response.data.path,
                    estado:'ACTIVO',
                    contentType: data.file.type
                }
                console.log('archivo',nuevoArchivo)
                this.state.archivos.unshift(nuevoArchivo)
                this.setState({show:false})
            }
        })
    }

    borrarArchivo(idArchivo){
        console.log(idArchivo)
        this.state.archivos.splice(idArchivo,1)
        this.setState({archivos: this.state.archivos})
    }


    render(){
        return(
       <>
       {/*
        (this.props.pago.checkoutID)?
        <article className="message is-danger w-100">
              <div className="message-body d-flex align-center">
                    Su membrecia ha vencido, pague para renovarla por 30 dias mas.
                  <Link to="/pagos" className="button is-dark is-outlined ml-auto"> Pagar</Link>
              </div>
          </article>
          :null
        */}
        { (this.state.loader) ?
            <CircularProgress color="secondary" />
            :(this.state.archivos.length>0) ? (this.state.archivos.length < 5) ?
            <button className="button is-dark is-outlined" onClick={()=>this.setState({show: true})}> <FontAwesomeIcon className="mr-3 color-success" icon={faPlusCircle} /> Subir archivo</button>
            :<Alert severity="info"
                    >
                        <AlertTitle>Has completado la cantidad maxima de archivos.</AlertTitle>
                        La cantidad maxima de archivos por usuario es 5, puedes eliminar alguno para subir uno nuevo.
                </Alert>
            :<div className="column is-full">
                <Alert severity="info"
                        action={
                            <Button color="inherit" size="small" onClick={()=>this.setState({show: true})}>
                            Subir archivo
                            </Button>
                        }
                    >
                        <AlertTitle>Aun no has cargado ningun archivo</AlertTitle>
                        Carga los que creas importantes para tu solicitud de trabajo
                </Alert>
            </div>
        }
        { (this.state.loader_cvs) ?
            <div className="mt-5 pt-5"><CircularProgress  color="secondary" /></div>
            : null
        }
        
       {
           this.state.archivos.map((element,index)=>(<ArchivoCard handleDelete={()=>this.borrarArchivo(index)} key={element.ID} archivoID={element.ID} type={element.contentType} archivo={element.archivo} title={element.archivo+" - "+element.createdAt.slice(0,10).replace(/-/g,"/")} />))
       }
       <Modal
        show={this.state.show}
        handleClose={()=>this.setState({show: false})}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
      <div className="container login__container">
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        Carga de archivos
                    </p>
                </header>
                <div className="card-content columns is-multiline">
                    <div className="field column  is-full">
                        <label className="label">Nombre <span>*</span></label>
                        <div className="control">
                            <input className="input" type="text" id="nombre-archivo" placeholder="ingresa un nombre que identifique el archivo"></input>
                        </div>
                    </div>
                    <DropzoneArea
                        onChange={this.getFiles.bind(this)}
                        acceptedFiles = {[".pdf", ".doc",".docx","image/*"]}
                        filesLimit={1}
                        maxFileSize={5242880}
                        dropzoneText={"Arrastra y suelta archivos o haz click para cargar"}
                        previewText={"Vista Previa"}
                        getFileLimitExceedMessage={(filesLimit) => `Solo puedes adjuntar ${filesLimit} archivo`}
                        getFileAddedMessage = {(fileName) => `Archivo ${fileName} agregado`}
                        getDropRejectMessage = {(rejectedFile,acceptedFiles,maxFileSize)=>{
                            let message = `Archivo ${rejectedFile.name} ha sido rechazado`
                            if(acceptedFiles.includes(rejectedFile.type)){
                                message += "Tipo de archivo no soportado"
                            }
                            if(rejectedFile.size>maxFileSize){
                                message += "El archivo no puede ser mayor a"+maxFileSize/1048576+" MB ."
                            }
                            return message
                        }}

                    />
                    <div className="field column  is-full">
                        <div className="control">
                            <Button variant="contained" onClick={()=>{this.guardarArchivo()}}>Guardar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </Modal>
      
        
       </>
        )
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion,
    pago: state.Pago
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_SESION",
          data
        })
      },
    
  })
export default connect(mapStateToProps,mapDispatchToProps) (Archivos) 