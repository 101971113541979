import React, { useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Link, useHistory,useSearchParams } from "react-router-dom";
import { Search } from "@material-ui/icons";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

function Filtro(props){
    const [selectedFilter,setSelectedFilter] = useState()
    let history = useHistory()
    let searchParams = new URLSearchParams(history.location.search)

    function filtrar(filterKey,option){
        if(searchParams.has(filterKey)){
            searchParams.delete(filterKey)
        }
        setSelectedFilter(option)
        history.push({
            pathname:history.location.pathname,
            search: searchParams.toString() +"&"+`${filterKey}=${option.ID}`
        })
    }

    const handleDeleteFilter = ()=>{
        console.log('delete filter')
        setSelectedFilter(null)
        if(searchParams.has(props.filterKey)){
            searchParams.delete(props.filterKey)
        }
        history.push({
            pathname:history.location.pathname,
            search: searchParams.toString()
        })
    } 

    return(
        <>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                >
                {props.titulo}
                </AccordionSummary>
                <AccordionDetails>
                {(!selectedFilter)?
                    <FormControl>
                    <RadioGroup
                        value={props.selected}
                        name="radio-buttons-group"
                    >
                        {
                            props.options.map(option=>
                            <FormControlLabel key={option.ID}
                                value={option.ID} 
                                control={<Radio onClick={()=>filtrar(props.filterKey,option)}/>}
                                label={option.name} />)
                        }
                    </RadioGroup>
                    </FormControl>
                    :
                    <Stack direction="row" spacing={1}>
                        <Chip label={selectedFilter.name} onDelete={handleDeleteFilter} />
                    </Stack>

                }
                
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export {Filtro}