import React from "react";
import { Link, useLocation } from "react-router-dom";

function ListaAvisos({children,totalPages,actualPage}){
    const location = useLocation()
    let query = new URLSearchParams(location.search)
    let page = parseInt(query.get('page'),10)
    //console.log('page en paginator',actualPage)
    if(query.has('page')){
        query.delete('page')
    }
    return (
        <>
        <div className="listado-container">
                {children}
        </div>
        
        </>
    )
}

export {ListaAvisos}