import React from 'react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux";
import IdiomasSubCard from "./IdiomasSubCard"
import {URL} from '../../../../Constants/API'
class IdiomasCard  extends React.Component{
    constructor(props){
      super(props)
      
    }
    handleCreate(e){
      e.preventDefault()
        let idiomas = this.props.idiomas
        idiomas.push({
            curriculumID:   this.props.sesion.curriculumID,
            nombre:         "",
            nivel:          "1",
            order:           1
        })
        console.log(this.props.idiomas)
        this.props.update(idiomas)
        this.forceUpdate()
    }
    componentDidMount(){
      /**Cargo los datos de los estudios */
     let config = { 
       method: "GET", 
       headers:{
           'Content-Type': 'application/json'}
     }
     fetch(URL+"curriculums/idiomas/curriculumID/"+this.props.sesion.curriculumID, config)
     .then(res => res.json())
     .then(response =>{
       //En caso de que no haya datos, pongo un valor "vacío"
       let idiomas = [
        {curriculumID:   this.props.sesion.curriculumID,
          nombre:         "",
          nivel:          "1",
          order:           1}]
      //---------------------------------------------------//
       let rows = (response.code <400) ? response.data.rows : idiomas
       this.props.update(rows)  
     })
     .catch(error => console.error(error))
    }
    render(){
        return(
            
            <div>
                {this.props.idiomas.map( (data,index,) =>(
                     <IdiomasSubCard key={"IdiomasCard-"+index} index={index} data={data}></IdiomasSubCard>
                ))}
                  
               
                  <div className="columns has-text-centered">
                  <div className="column is-full">
                    <FontAwesomeIcon className="mr-3 cursor-pointer color-success" size="2x" icon={faPlusCircle} onClick={(e)=>this.handleCreate(e)} />
                  </div>
                </div>
             </div>
        );
        
    }
}
const mapStateToProps = state => ({
  sesion: state.Sesion,
  idiomas: state.Idiomas
})

const mapDispatchToProps = dispatch => ({
  update(data) {
    dispatch({
      type: "UPDATE_IDIOMAS",
      data
    })
  },
})
export default connect(mapStateToProps,mapDispatchToProps)(IdiomasCard)