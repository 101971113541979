import React from 'react'
import { Link  } from 'react-router-dom';
import { connect } from "react-redux";
import { faAddressCard, faBars, faHome , faDollarSign, faQuestionCircle, faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../Assets/micvLogo.png'
import Deuda from '../../Constants/Deuda'
class NavBar extends React.Component{
    constructor(props){
        super(props)
        this.state={
            active: null
        }
    }
    burgerToggle(option){
        switch (option){
            case null:
                return "burger-toggle"
            case true:
                return "burger-toggle active-burger-in"
            case false:
                return "burger-toggle active-burger-out"
            default:
                return "burger-toggle"
        }
    }
    render(){
        return(
            <>
            <Deuda/>
            <div className="navbar is-primary shadow" role="navigation" aria-label="main navigation">
                <div className="container">
                    <div className="navbar-brand ">
                        <label className="navbar-item">
                            <Link to="/">
                                <img src={logo}/>
                            </Link>
                        </label>
                        <div className="navbar-item navbar-item-burger">
                            <FontAwesomeIcon className=" navbar-burger" icon={faBars} onClick={()=>{this.setState({active:true})}}/>
                        </div>
                        
                    </div>
                    
                    <div className="navbar-menu">
                        <Link className="navbar-item" to="/home">Inicio</Link>
                        <Link className="navbar-item" to="/avisos">Avisos laborales</Link>
                        <Link className="navbar-item" to="/precios">Precios</Link>
                        <Link className="navbar-item" to="/contacto">Contacto para empresas</Link>
                        <Link className="navbar-item" to="/contacto-personas">Ayuda con tu curriculum</Link>
                        {/**<Link className="navbar-item">Precios</Link>**/}
                        <div className="navbar-item">
                            <div className="buttons">
                                {(!this.props.sesion.personaID)?  
                                    <Link className="button is-link is-inverted is-outlined is-rounded" to="/login">Login</Link> 
                                    :<Link className="button is-link is-inverted is-outlined is-rounded" to="/curriculums"  >Mi cuenta</Link> 
                                }   
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className={  this.burgerToggle(this.state.active)} onClick={ (e) => {

                        if(e.target !== e.currentTarget) return;
                        console.log( 'clicked the burger-toggle' );
                        this.setState({active:false})
                        
            }}>
                <div className="burger-menu">
                    <Link className="navbar-item" to="/home"><FontAwesomeIcon icon={faHome}/>Inicio</Link>
                    <Link className="navbar-item" to="/avisos"><FontAwesomeIcon icon={faBriefcase}/>Avisos Laborales</Link>
                    <Link className="navbar-item" to="/precios"><FontAwesomeIcon icon={faDollarSign}/>Precios</Link>
                    <Link className="navbar-item" to="/contacto"><FontAwesomeIcon icon={faAddressCard}/>Contacto para empresas</Link>
                    <Link className="navbar-item" to="/contacto-personas"><FontAwesomeIcon icon={faQuestionCircle}/>Ayuda con tu curriculum</Link>
                    
                    <div className="navbar-item">
                        <div className="buttons">
                            {(!this.props.sesion.personaID)?  
                                <Link className="button is-link is-inverted is-outlined is-rounded" to="/login">Login</Link> 
                                :<Link className="button is-link is-inverted is-outlined is-rounded" to="/curriculums"  >Mi cuenta</Link> 
                            }   
                        </div>
                    </div>
                </div>


            </div>
            </>
        )
    }
    
}
const mapStateToProps = state => ({
    sesion: state.Sesion,
  })

const mapDispatchToProps = dispatch => ({
    
    
    
  })
export default connect(mapStateToProps,mapDispatchToProps)(NavBar) ;