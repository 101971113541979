import React, { useState  } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../Sass/experienceCard.scss'
function ExperienceCard(props){
    const [active, setActive] = useState(false)
    function handleClick(e) {
        e.preventDefault();
        setActive(!active)
    }
    return(
        <div className="card mb-5" >
            <header className="card-header level experienceCard" onClick={handleClick}>
                <div className="level-left-exp">
                    <div className="level-item px-5 py-3">
                        <span className="icon"><FontAwesomeIcon icon={props.icon} /></span>
                    </div>
                </div>
                <div className='level-right-exp'>
                        <p>{props.title}</p> 
                </div>
            </header>
            <div className={active? "card-content" : "card-content  is-hidden" } >
                
                {props.children}
                
            </div>
        </div>
    )
    
}
export default ExperienceCard