import React, {useState, useEffect } from 'react'
import sha3 from 'crypto-js/sha3'
import { connect } from "react-redux"
import {Link, Redirect} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress'
import {URL} from '../../Constants/API'
import ReactGA from 'react-ga';

const RecuperarPassword = ({sesion, update}) =>{
    const [emailRecuperacion, setEmailRecuperacion] = useState()
    const [successCodigo, setSuccessCodigo] = useState(false)
    const [recuperacionData, setRecuperacionData] = useState({email:'',codigo:'',password:''})
    const [failure, setFailure] = useState(false)
    const [errorCode, setErrorCode] = useState(false) 
    const [loader, setLoader] =useState(false)
    const [ChangePwSuccess,setChangePwSuccess] = useState(false)

    useEffect(() => {
        ReactGA.initialize('UA-142549046-23');
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(window.location.pathname + window.location.search)
      });

    const handleInputChange =(event) =>{
        setEmailRecuperacion(event.target.value)
    }

    const handleGenerarCodigo = ()=>{
        setLoader(true)
        let data = {
            motivo:'password' ,
            email: emailRecuperacion
        }
        let config = { 
            method: 'PATCH', 
            body: JSON.stringify(data),
            headers:{'Content-Type': 'application/json'}}

        fetch(URL + "personas/generar-codigo/id/", config)
        .then(res =>res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
            setLoader(false)
            console.log("Recuperación: ",response)
            if(response.code>=404){
                setSuccessCodigo(false)
                setFailure(true)
            }else{
                //saveCV(response.data.ID)
                setSuccessCodigo(true)
                setFailure(false)
            }
        });
    }

    const handleIrValidar = ()=>{
        setSuccessCodigo(true)
    }

    const encriptar = (pass) =>{
        console.log(pass)
        let hash = sha3(pass).toString()
        let sal= ''
        for(let i=0;i<6;i++){
            sal = sal + Math.round(Math.random()*9)
        }
        let hash_salado = sal + hash
        let hash_reforzado = sha3(hash_salado).toString() 
        let enviar = sal + hash_reforzado
        return enviar
    }

    const handleCambiarPassword = ()=>{
        setLoader(true)
        let encPw = encriptar(document.getElementById('recuperar-pw').value)
        let email = document.getElementById('recuperar-email').value
        let codigo = document.getElementById('recuperar-codigo').value
        console.log(encPw)
        let data = {
            email: email,
            password: encPw,
            codigo: codigo
        }
        /*setRecuperacionData(
            {...recuperacionData,
                password: encPw
        })
        console.log(recuperacionData)*/
        let config = { 
            method: 'PATCH', 
            body: JSON.stringify(data),
            headers:{'Content-Type': 'application/json'}}

        fetch(URL + "personas/cambiar-password", config)
        .then(res =>res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
            setLoader(false)
            console.log("Cambio pw: ",response)
            if(response.code>=404){
                setErrorCode(true)
            }else{
                setErrorCode(false)
                setChangePwSuccess(true)
            }
        })
    }
    
    return(
        <>
        
        
            <div className="container login__container">
            <div className="card">
                    <header className="card-header">
                        <p className="card-header-title">
                            Recuperación de contraseña
                        </p>
                    </header>
                    {(!successCodigo)?
                        <div className="card-content columns is-multiline">
                            <div className="field column is-full">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input className='input' type="email" placeholder="Email" name="user"  onChange={handleInputChange}/>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="card-content columns is-multiline">
                            <div className="field column is-full">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input className='input' type="email" id='recuperar-email' placeholder="Email" name="email"/>
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">Nueva contraseña</label>
                                <div className="control">
                                    <input className='input' type="password" id='recuperar-pw' placeholder="Nueva contraseña" name="password"/>
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">Código</label>
                                <div className="control">
                                    <input className='input' type="text" id='recuperar-codigo' placeholder="Código" name="codigo"/>
                                    {(errorCode)?
                                        <p className='text-danger'>Código incorrecto</p>
                                        :null
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    }
                   
                </div>
                <div className="columns  is-variable is-multiline is-centered">
                    { (loader) ?
                        <CircularProgress color="secondary" />
                        :(!successCodigo)?
                            <>  
                                <Link className="button  my-5 is-rounded" onClick={()=>{handleGenerarCodigo()}}>
                                    Enviar código
                                </Link>
                                <Link className="button  my-5 is-rounded" onClick={()=>{handleIrValidar()}}>
                                    Ya tengo un código
                                </Link>
                            </>
                            :
                            <Link className="button  my-5 is-rounded" onClick={()=>{handleCambiarPassword()}}>
                                Cambiar contraseña
                            </Link>
                    }
                   
                    {(ChangePwSuccess) ?  <Redirect to='/login' /> : null}
                </div>
                <div> 
                   <p className="has-text-white">Si aún no tienes una cuenta puedes   <Link className="login__link" to="/registro"> registrarte aquí</Link>. </p>
                </div>
            </div>
        
        </>
    )
}


const mapStateToProps = state => ({
    sesion: state.Sesion
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_SESION",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps) (RecuperarPassword)