import React from 'react'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux";
import {HOST} from '../../../Constants/API'
class CardTemplate extends React.Component  {
    constructor(props){
        super(props);
        this.state ={
            pdf: this.props.data
        }
    }
    
    handleClick = (e) =>{
        if(!this.props.disable){
            e.preventDefault()
            this.props.update(this.state.pdf)
        }
        
    }
    addClass(){
        if(this.props.disable){
            return "border-template disable is-4 card card-template mx-5 mb-5"
        }else{
            if(this.props.data.ID === this.props.pdf.ID){
                return "border-template is-4 card card-template mx-5 mb-5"
            }else{
                return "is-4 card card-template mx-5 mb-5"
            }
        }
    }
    render(){ 
        return(<>
            <div className={this.addClass()} onClick={(e)=>this.handleClick(e)} >
                            <header className="card-header level">
                            <div className="level-left">
                                <div className="level-item">
                                    <h2 className="title title-template is-5 mx-3 my-3">{this.props.data.nombre}</h2>
                                </div>
                            </div>
                            <div className="level-right">
                                <div className="level-item">
                                    <FontAwesomeIcon className={(this.props.data.ID === this.props.pdf.ID) ? "mr-3 color-success " : "is-hidden"}  icon={faCheckCircle} />
                                    
                                </div>
                            </div>
                    
                                
                               
                            </header>
                            <div className="card-content">
                                <img src={HOST+this.state.pdf.imgPreview}/>
                            </div>
            </div>
            </>)
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion,
    pdf: state.Pdf
  })

const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_PDF",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps)(CardTemplate) 
