import React,  {useState} from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import SeleccionArchivos from '../../../Components/SeleccionArchivos'
import {URL} from '../../../Constants/API'
function Mail({sesion}){
    const [mail, setMail] = useState({value: "", control:false})
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false)
    const [loader, setLoader] =useState(false)
    var fileIDs = []

    const inputChange =(event) =>{

        setMail(
            {
                value:  event.target.value,
                control: isEmail(event.target)
            })
        
    }

    const agregarArchivo = (id,snCheked) =>{
        console.log(sesion)
        if(snCheked){
            fileIDs.push(id)
        }else{
            let index = fileIDs.indexOf(id)
            fileIDs.splice(index,1)
        }
        console.log(fileIDs)
    }

    const submit = () =>{
        let body= { mail: mail.value,
                    fileIDs: fileIDs}
        let config = { 
            method: 'POST', 
            body: JSON.stringify(body),
            headers:{'Content-Type': 'application/json'}
        }
        if(mail.control){
            setLoader(true)
            fetch(URL + `curriculums/mail/id/${sesion.curriculumID}/idP/${sesion.personaID}`, config)
            .then(res =>res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
                setLoader(false)
                console.log(response)
                if(response){
                    if(response.code>=400){
                        setSuccess(false)
                        setFailure(true)                                        
                    }else{                    
                        setSuccess(true)
                        setFailure(false)                    
                    }
                }  
            });
        }
        
    }
    const isEmail= (e) =>{
        let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(regex.test(e.value)){
            e.className = "input is-success"
            return true
        }else{
            e.className = "input is-danger"
            return false
        }
    }
    const addClass = () =>{
        if(success){
            return "input is-success"
        }else if(failure){
            return "input is-danger"
        } else{
            return "input"  
        }
    }
    return(
        <>
        {
        (success) ?
        <div className="container" style={{overflow:'hidden'}}>
            <div className="card">    
                <div className="card-content columns is-multiline">
                    <div className="field column is-full">
                        <label className="label">El mail fue enviado correctamente <br/>al remitente {mail.value}</label>    
                    </div>
                </div>   
            </div>
            <div className="columns  is-variable is-multiline is-centered"> 
                <a className="button  my-5 is-rounded" onClick={()=>{setSuccess(false)}}>Enviar otro mail</a>
            </div>
        </div>
        :
            <div className="container" style={{overflow:'hidden'}}>
                <div className="card">
                    <header className="card-header">
                        <p className="card-header-title">
                            Envío de email
                        </p>
                    </header>
                    <div className="card-content columns is-multiline">
                        <div className="field column is-full">
                            <label className="label">Email</label>
                            <div className="control">
                                <input className= {addClass()} type="email" placeholder="Email" name="email"  onInput={(e)=>{inputChange(e)}}/>
                            </div>
                        </div>
                        <div className="column is-full">
                            <label className="label">Adjuntar archivos</label>
                            <SeleccionArchivos handleChange={(id,event)=>{agregarArchivo(id,event.target.checked)}}></SeleccionArchivos>
                        </div>
                    </div>   
                </div>
                <div className="columns  is-variable is-multiline is-centered">
                    {(loader) ?
                        <CircularProgress color="secondary" />
                        : <a className="button  my-5 is-rounded" onClick={()=>{submit()}}>Enviar</a>
                    }
                </div>
            </div>
        }
        </>
    )
}
const mapStateToProps = state => ({
    sesion: state.Sesion
  })
  
  const mapDispatchToProps = dispatch => ({
   
  })
export default connect(mapStateToProps,mapDispatchToProps) (Mail)