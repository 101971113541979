import React from 'react'
import { Document, Page,pdfjs } from 'react-pdf';
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from "react-redux";
import {Link} from 'react-router-dom'
import {HOST, URL} from '../../../Constants/API'
import Modal from '../../../Components/Modal'
import Mail from '../4.Mail/Mail'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
class Preview extends React.Component{
    constructor(props){
        super(props)
        this.state={
            loader: false,
            numPages: null,
            pageNumber: 1,
            pdf: {
                url: "",
                blob: ""
                },
            show: false,
            showDeuda: false
        }
    }
    onDocumentLoadSuccess({ numPage }) {
        this.setState({numPages: numPage});
      }
    componentDidMount(){
        
        /**Traigo el CV*/
        let config = {
            method: "GET", 
            headers:{'Content-Type': 'application/json'}}
        fetch(URL + "curriculums/pdf/id/"+this.props.sesion.curriculumID, config)
        .then(res => res.json())
        .then(response => {
            console.log("Exito", response)
            this.setState({pdf:{ ...this.state.pdf, url: HOST+response.data.url}})
            this.setState({loader:false})    
        })
        .catch(error => console.error("Error: ", error))
    }
    handleClick(e){
        e.preventDefault()
        if(this.props.pago.checkoutID){
            this.setState({showDeuda:true})
        }else{
            let fileName= this.state.pdf.url.split("/").pop()
            this.Download(this.state.pdf.url, fileName )
        }
        
    }
    Download(url, name){
        function forceDownload(blob, filename) {
            var a = document.createElement('a');
            a.download = filename;
            a.href = blob;
            // For Firefox https://stackoverflow.com/a/32226068
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
          
          // Current blob size limit is around 500MB for browsers
          function downloadResource(url, filename) {
            if (!filename) filename = url.split('\\').pop().split('/').pop();
            fetch(url, {
                headers: new Headers({
                  'Origin': null
                }),
                mode: 'cors'
              })
              .then(response => response.blob())
              .then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                forceDownload(blobUrl, filename);
              })
              .catch(e => console.error(e));
          }
          
          downloadResource(url, name);
    }
    SendEmail(e){
        e.preventDefault()
        if(this.props.pago.checkoutID){
            this.setState({showDeuda:true})
        }else{
            this.setState({show: true})
        }
    }
    render(){
        return (
            <>
            <section className="section section-2">
                            <div className="container">
                                <div className="columns  is-variable is-multiline is-centered"> 
             { (this.state.loader) ?
                        <>
                        <label className="has-text-white px-5">Creando curriculum... </label> 
                        <CircularProgress color="secondary" />
                        </>
                        :
                         
                                    <div className="card card-preview">
                                        <div className="card-content">
                                            <Document
                                                file={this.state.pdf}
                                                onLoadSuccess={(e)=>this.onDocumentLoadSuccess(e)}
                                            >
                                                <Page pageNumber={this.state.pageNumber} />
                                            </Document>
                                    
                                        </div>
                                        <footer className="card-footer px-5 py-5 is-centered">
                                            <button onClick={(e)=>this.handleClick(e)} target="_blank" className="button is-rounded mr-2" > Descargar CV</button>
                                            <Link className='button is-rounded mr-2' to='/curriculums'> Mis curriculums</Link>
                                            <button onClick={(e)=>this.SendEmail(e)} target="_blank" className="button is-rounded" > Enviar</button>
                                        </footer>
                                    </div>
                                
                    }
                        </div>
                    </div>
            </section>
            {/**Modal de deuda */}
            <Modal show={this.state.showDeuda} handleClose={()=>this.setState({showDeuda: false})}>
                <article className="message is-danger">
                    <div className="message-body d-flex align-center">
                        Para continuar con el proceso es necesario acceder a la membresía por 30 días.
                        <Link to="/pagos" className="button is-dark is-outlined ml-auto"> Pagar</Link>
                    </div>
                </article>
            </Modal>
            {/**Modal de envío de pdf */}
            <Modal show={this.state.show} handleClose={()=>this.setState({show: false})}>
                <Mail/>
            </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    sesion: state.Sesion,
    pdf: state.Pdf,
    pago: state.Pago
  })

const mapDispatchToProps = dispatch => ({
    
    
  })
export default connect(mapStateToProps,mapDispatchToProps)(Preview) 