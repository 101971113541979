import React from "react";
import {Card,Chip} from '@mui/material';
import {Link} from 'react-router-dom'




function AvisoCard(props){
    
    function formatFecha(date){
        let fecha = new Date(date)
        let fechaFormateada = fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear()
        return fechaFormateada
    }

    let aviso = props.data
    return(
        <div className="item-listado">
        <Link to={`/avisos/${aviso.ID}`}>
        <Card>
            <div className="titular">
                <span className="item-title">{aviso.titulo}</span>
                <span className="item-fecha">
                    <Chip label={formatFecha(aviso.createdAt)} variant="outlined" />
                </span>
            </div>
            <div className="detalles">
                {
                    (aviso.salario)? 
                        <div className="description-container">
                        <span className="item-label">Salario mensual aproximado: </span>
                        <span className="item-description">${aviso.salario}</span>
                        </div>
                    : null
                }
                
                {
                    (aviso.empresa)?
                    <div className="description-container">
                        <span className="item-label">Empresa: </span>
                        <span className="item-description">{aviso.empresa}</span>
                    </div>
                    : null
                }



                <div className="description-container">
                    <span className="item-label">Ubicación: </span>
                    <span className="item-description">{aviso.localidad.nombre}, {aviso.provincia.nombre}</span>
                </div>

                {
                    (aviso.introduccion)?
                    <div className="description-container">
                        <span className="item-description">{aviso.introduccion}</span>
                    </div>
                    : null
                }
            </div>
            
            
            
        </Card>
        </Link>
        </div>
    )
}

export {AvisoCard}