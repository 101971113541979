import React, {useState, useEffect } from 'react'
import sha3 from 'crypto-js/sha3'
import { connect } from "react-redux"
import {Link, Redirect} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress'
import {URL} from '../../Constants/API'
import ReactGA from 'react-ga';
import '../../Sass/login.scss'

const Login = ({sesion, update}) =>{
    const [login, setLogin] = useState({user:"", password:""})
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false)
    const [loader, setLoader] =useState(false)

    useEffect(() => {
        ReactGA.initialize('UA-142549046-23');
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(window.location.pathname + window.location.search)
      });

    const handleInputChange =(event) =>{
        setLogin({
            ...login,
            [event.target.name] : event.target.value
        })
        
    }

    const handleLogin = ()=>{
        setLoader(true)
        let pass =sha3(login.password).toString()
        let data = {
            user:login.user ,
            password: pass
        }
        let config = { 
            method: 'POST', 
            body: JSON.stringify(data),
            headers:{'Content-Type': 'application/json'}}

        fetch(URL + "personas/login", config)
        .then(res =>res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
            setLoader(false)
            console.log("Login: ",response)
            if(response){
                if(response?.code>=400){
                    setSuccess(false)
                    setFailure(true)
                }else{
                    saveCV(response.data.ID)
                    setSuccess(true)
                    setFailure(false)
                    update({...sesion,
                            personaID: response.data.ID,
                            cuil: response.data.cuil,
                            planPagoID: response.data['planesPagos'][0]['ID']    
                        })
                    
                }
            }
        });
    }
    const saveCV=(personaID)=>{
        let body= { personaID: personaID}
        let config = { 
            method: 'PATCH', 
            body: JSON.stringify(body),
            headers:{'Content-Type': 'application/json'}}

        fetch(URL + "curriculums/persona/id/"+sesion.curriculumID, config)
        .then(res =>res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {console.log(response)});

    } 
    const handleLoginContol = () =>{
        if(success){
            return "input is-success"
        }else if(failure){
            return "input is-danger"
        } else{
            return "input"  
        }
    }
    return(
        <>
        
        
            <div className="container login_container">
                <div className="card">
                    <header className="card-header">
                        <p className="card-header-title">
                            Inicio de sesión
                        </p>
                    </header>
                    <div className="card-content columns is-multiline">
                        <div className="field column is-full">
                            <label className="label">Email</label>
                            <div className="control">
                                <input className= {handleLoginContol()} type="email" placeholder="Email" name="user"  onChange={handleInputChange}/>
                            </div>
                        </div>
                        <div className="field column is-full">
                            <label className="label">Contraseña</label>
                            <div className="control">
                                <input className= {handleLoginContol()} type="password" placeholder="Contraseña" name="password"  onChange={handleInputChange}/>
                            </div>
                        </div>
                        <div className='field column is-full'>
                            <Link to="/recuperar-contraseña">¿Olvidaste tu contraseña?</Link>
                        </div>
                    </div>
                    <div className="columns  is-variable is-multiline is-centered text-center">
                    { (loader) ?
                        <CircularProgress color="secondary" />
                        :  <Link className="button  my-5 is-rounded" onClick={()=>{handleLogin()}}  >Iniciar sesión </Link>
                    }
                   
                    {(success || sesion.personaID) ?  <Redirect to='/curriculums' /> : null}
                    </div>
                </div>
                
                <div className="card registro"> 
                    <header className="card-header">
                        <p className="card-header-title">
                            Crear cuenta
                        </p>
                    </header>
                    <div className="column is-full">
                        <label className="label text-center">¿No tienes cuenta?</label>
                        <p className="text-center"><Link className="button is-rounded" to="/registro"> Regístrate aquí</Link></p>
                    </div>
                </div>
            </div>
        
        </>
    )
}


const mapStateToProps = state => ({
    sesion: state.Sesion
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_SESION",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps) (Login)