import React from 'react'
import {useState, useEffect} from 'react'
import '../../../Sass/avisos.scss'
import {ListaAvisos} from './ListaAvisos'
import {AvisoCard} from './AvisoCard'
import Footer from '../Footer'
import {Filtro} from './Filtro'
import {URL} from '../../../Constants/API'
import { useHistory, useLocation, useParams, Link } from 'react-router-dom'
import { ListaVaciaAvisos } from './ListaVaciaAvisos'
import Pagination from '@mui/material/Pagination'
import { Accordion, AccordionDetails, AccordionSummary, PaginationItem, InputBase, IconButton, Paper, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search'

function Avisos(){
    let filtrosRubro = []
    let filtrosUbicaciones = []
    let filtrosContrato = [{name:'Tiempo parcial',ID:'PARCIAL'},{name:'Tiempo completo',ID:'COMPLETO'}]
    //let ubicaciones = [{name:"ubicacion 1",ID:1},{name:"ubicacion 2",ID:2}]
    let location = useLocation()
    let query = new URLSearchParams(location.search)
    
    let rubroFilter = parseInt(query.get('rubro'),10)
    let contratoFilter = query.get('contrato')
    let provinciaFilter = parseInt(query.get('ubicacion'),10)
    let perPage = 15
    let pageRubros = 1
    let perPageRubros = 30
    let history = useHistory()
    let filtroInicial = {}
    if(rubroFilter){
        filtroInicial = {...filtroInicial,rubroID:rubroFilter}
    }
    if(contratoFilter){
        filtroInicial = {...filtroInicial,contrato:contratoFilter}
    }
    const [filters,setFilters] = useState(filtroInicial)
    const [avisos,setAvisos] = useState([])
    const [rubros, setRubros] = useState([])
    const [ubicaciones,setUbicaciones] = useState([])
    const [page, setPage] = useState(parseInt(query.get('page'),10))
    const [search,setSearch] = useState()
    const [pageAvisos,setPagesAvisos] = useState()
    const [totalPagesAvisos,setTotalPageAvisos] = useState()


    useEffect(()=>{
        let newFilter = {}
        if(rubroFilter){
            newFilter.rubroID = rubroFilter
        }
        if(contratoFilter){
            newFilter.contrato = contratoFilter
        }
        if(provinciaFilter){
            newFilter.provinciaID = provinciaFilter
        }
        setFilters(newFilter)
        setPage(1)
    },[rubroFilter,contratoFilter,provinciaFilter])

    useEffect(()=>{
        console.log('page effect', page)
        console.log('filtros',filters)
        if(!page){
            setPage(1)
        }else{
            loadAvisosPage(page-1,filters,search)
        }
        /*if(!page){
            history.push({
                pathname:history.location.pathname,
                search: '?page=1'
            })
            loadAvisosPage(0,filters,null)
        }else{
            let p = page-1
            loadAvisosPage(p,filters,null)
        }*/
    },[page,filters,search])

    function loadAvisosPage(page,filters,search){
        let urlGet
        let config
        console.log(filters===null)
        console.log(search)
        if(filters.rubroID || filters.contrato){
            if(search){
                let body = {
                    like:search,
                    filters:filters
                }
                config={
                    method:'POST',
                    body: JSON.stringify(body),
                    headers:{'Content-Type':'application/json'}
                }
                urlGet = URL+`avisos/search/filter/p/${page}/l/${perPage}`
            }else{
                config={
                    method:'POST',
                    body: JSON.stringify(filters),
                    headers:{'Content-Type':'application/json'}
                }
                urlGet = URL+`avisos/filter/p/${page}/l/${perPage}`
            }
        }else{
            if(search){
                config={
                    method:'POST',
                    body: JSON.stringify({like:search}),
                    headers:{'Content-Type':'application/json'}
                }
                urlGet = URL+`avisos/search/p/${page}/l/${perPage}`
            }else{
                config = {
                    method:'GET'
                }
                urlGet = URL+`avisos/p/${page}/l/${perPage}`
            }
        }
        fetch(urlGet,config)
        .then(response=>{
            return response.json()
        })
        .catch(error => console.error('Error:', error))
        .then(result=>{
            console.log(result)
            if(result?.code == 200){
                let totalP
                setAvisos(result.data.rows)
                totalP = Math.ceil(result.data.count/perPage)
                setTotalPageAvisos(totalP)
            }else{
                setAvisos([])
                setTotalPageAvisos(0)
            }
        })
    }

    

    useEffect(()=>{
        //get de categorías
        let configPost = {
            method:"POST",
            body: JSON.stringify({like:"%"}),
            headers:{'Content-Type': 'application/json'}
        }
        fetch(URL+`curriculums/rubros/search/p/${pageRubros}/l/${perPageRubros}`,configPost)
        .then(response=>{
            console.log(response)
            return response.json()
        })
        .catch(error => console.error('Error:', error))
        .then(rubros=>{
            if(rubros?.code==200){
                cargarFiltrosRubros(rubros.data.rows)
            }
        })
    },[])

    function cargarFiltrosRubros(rubros){
        rubros.map(rubro=>{
            let filtroRubro = {
                name:rubro.nombre,
                ID:rubro.ID
            }
            filtrosRubro.push(filtroRubro)
        })
        setRubros(filtrosRubro)
    }

    useEffect(()=>{
        //get de ubicaciones
        let configGet = {
            method:"GET",
            headers:{'Content-Type': 'application/json'}
        }
        fetch(URL+`extras/provincias`,configGet)
        .then(response=>{
            console.log(response)
            return response.json()
        })
        .catch(error => console.error('Error:', error))
        .then(ubicaciones=>{
            if(ubicaciones?.code===200){
                cargarFiltrosUbicacion(ubicaciones.data)
            }
        })
    },[])

    function cargarFiltrosUbicacion(ubicaciones){
        ubicaciones.map(rubro=>{
            let filtroUbicacion = {
                name:rubro.nombre,
                ID:rubro.ID
            }
            filtrosUbicaciones.push(filtroUbicacion)
        })
        setUbicaciones(filtrosUbicaciones)
    }
    
    /*let avisos = [
        {
            "ID": 6,
            "titulo": "aviso con cambio form",
            "empresa": "una empresa",
            "contrato": "COMPLETO",
            "vacantes": 5,
            "salario": 5000,
            "createdAt": "2022-06-19T02:35:03.000Z",
            "provincia": {
                "ID": 20,
                "nombre": "San Luis"
            },
            "localidad": {
                "ID": 1815,
                "nombre": "Villa Mercedes"
            }
        }
    ]*/

    const changePagination = (event,value)=>{
        console.log(value)
        setPage(value)
    }

    const handleSearch = (event)=>{
        event.preventDefault()
        let busqueda = document.getElementById('busqueda').value
        setSearch(busqueda)
    }

    const handleDeleteSearch = ()=>{
        let busqueda = document.getElementById('busqueda').value
        console.log(busqueda)
        if(busqueda.length === 0){
            setSearch(null)
        }
    }

    return(
        <>
        <section className='hero is-medium'>
        {/*<div className='hero-body'>
            <div className='container'>
                <h4>aquí el buscador</h4>
            </div>
        </div>*/
        }
        </section>
        <section className='section'>
            <div className='container'>
                
                <div className='avisos-container'>
                    <div className='filter-row-mobile'>
                        <Accordion className='filters-mobile'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                            <Typography sx={{fontWeight:'bold'}}>
                                Filtros
                            </Typography> 
                            </AccordionSummary>
                            <AccordionDetails>
                            <Filtro titulo="Rubros/Categorías" filterKey="rubro" selected={rubroFilter}  options={rubros}>
                            </Filtro>
                            <Filtro titulo="Tipo de contrato" filterKey="contrato" selected={contratoFilter} options={filtrosContrato}>
                            </Filtro>
                            <Filtro titulo="Ubicación" filterKey="ubicacion" selected={provinciaFilter} options={ubicaciones}>
                            </Filtro>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='filter-column'>
                        <Filtro titulo="Rubros/Categorías" filterKey="rubro" selected={rubroFilter}  options={rubros}>
                        </Filtro>
                        <Filtro titulo="Tipo de contrato" filterKey="contrato" selected={contratoFilter} options={filtrosContrato}>
                        </Filtro>
                        <Filtro titulo="Ubicación" filterKey="ubicacion" selected={provinciaFilter} options={ubicaciones}>
                        </Filtro>
                    </div>
                    <div className='list-column'>
                        <div className='buscador-container'>
                            <Paper
                                component="form"
                                onSubmit={handleSearch}
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                                >
                            <InputBase
                                id='busqueda'
                                sx={{width:'100%',paddingLeft:'10px'}}
                                placeholder="Busca avisos laborales"
                                onKeyUp={handleDeleteSearch}
                                inputProps={{ 'aria-label': 'Busca avisos laborales' }}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search"  onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton>
                            </Paper>
                        </div>
                        {
                            (avisos?.length>0)?
                            <>
                            <ListaAvisos totalPages={totalPagesAvisos}>
                            {
                                avisos?.map(aviso=>(<AvisoCard key={aviso.ID} data={aviso}/>))
                            }
                            </ListaAvisos>
                            <div className="paginator-container">
                                <Pagination 
                                    page={page}
                                    count={totalPagesAvisos} 
                                    variant="outlined" 
                                    onChange={changePagination}
                                    shape="rounded"
                                    renderItem={(item)=>(
                                        <PaginationItem 
                                            /*component={Link} */
                                            /*to={`/avisos?page=${item.page}&${query.toString()}`}*/
                                            {...item}
                                        ></PaginationItem>
                                    )}
                                />
                                    
                            </div>
                            </>
                            :
                            <ListaVaciaAvisos/>
                        }
                        
                    </div>
                </div>
            </div>
        </section>

        <Footer/>
        </>
    )
}



export {Avisos}