import React from 'react'
import { Link, Redirect } from 'react-router-dom';
import sha3 from 'crypto-js/sha3'
import Modal from  '../../Components/Modal'
import { connect } from "react-redux"
import {URL} from '../../Constants/API'
import CircularProgress from '@material-ui/core/CircularProgress'
import ReactGA from 'react-ga';

class ContactoPersonas extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          mensaje: {
            nombre:"",
            email:"",
            telefono:"",
            mensaje: ""
            },
          success: false,
          failure: false,
          control: true,
          show: false,
          loader: false,
          errorMsj: ""
        };
    }
    
    
    handleInputChange =(event) =>{
        this.setState({ mensaje : {...this.state.mensaje,
            [event.target.name] : event.target.value}
            
        })
       
    }
    

    
   
    componentDidMount() {
        //Google Analitycs
        ReactGA.initialize('UA-142549046-23');
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(window.location.pathname + window.location.search)
        
       
    }
    handleContacto = async () =>{
        await this.setState({control:true})
        this.isEmpty(document.getElementById("nombre"))
        this.isEmail(document.getElementById("email"))
        this.isTel(document.getElementById("telefono"))
        this.isEmptyTextArea(document.getElementById("mensaje"))
        if(this.state.control){
            this.setState({loader: true})
            let API_MAILS = URL+"contacto/mail-empresa"
            let config = {  method: 'POST',
                    body: {}, 
                    headers:{
                        'Content-Type': 'application/json'
                    }
                }
            config['body'] = JSON.stringify({
                    cabecera:"Una persona se contacto por ayuda en creación de curriculum",
                    mensaje: `
                        Nombre y apellido:${this.state.mensaje.nombre}<br>
                        Email: ${this.state.mensaje.email}<br>
                        Telefono: ${this.state.mensaje.telefono}<br>
                        Mensaje: ${this.state.mensaje.mensaje}<br>
                    
                    `,
                    asunto:"Micurriculum.com.ar - Solicitud de ayuda",
                    mail:"contacto@micurriculum.com.ar",
                    nombre:"Micurriculum.com.ar",
                    to:"contacto@micurriculum.com.ar",
                    base64: []
                })
            fetch(API_MAILS, config).then(res=>res.json()).catch(error=>console.error(error))
            .then(response=>{
                console.log(response)
                this.setState({loader: false})
                if(response['code']<400){
                    this.setState({success: true})
                    this.showModal("El mensaje fue enviado con éxito")
                    
                }else{
                    this.setState({success: false})
                    this.showModal( "El mensaje no se pudo enviar, porfavor enviar más tarde")
                    
                }
                
            })
            
           
        }else{
            this.setState({success: false})
            this.showModal("No se puede avanzar hasta completar correctamente todos los campos del formulario.")
        }
    }
    
    isEmpty = (e)=>{
        if(!e.value){
            e.className = "input is-danger"
            this.setState({control: false})
        }else{
            e.className = "input is-success"
        }
    }
    isEmptyTextArea = (e)=>{
        if(!e.value){
            e.className = "textarea is-danger"
            this.setState({control: false})
        }else{
            e.className = "textarea is-success"
        }
    }
    isEmail= (e) =>{
        let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(regex.test(e.value)){
            e.className = "input is-success"
        }else{
            e.className = "input is-danger"
            this.setState({control: false})
        }
    }
    isTel= (e) =>{
        let regex = /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/;
        if(regex.test(e.value)){
            e.className = "input is-success"
        }else{
            e.className = "input is-danger"
            this.setState({control: false})
        }
    }
    showModal = (msj) => {
        this.setState({show: true})
        this.setState({errorMsj: msj})
    }

    hideModal = () => {
        this.setState({show: false})
    }
    render(){
        return(
            <div className="section section-2">
                <div className="container">
                <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                Servicio personalizado de armado y corrección de CV. Contáctanos
                            </p>
                        </header>
                        <div className="card-content columns is-multiline">
                           
                            <div className="field column is-full">
                                <label className="label">Nombre y apellido</label>
                                <div className="control">
                                    <input className= "input" type="text" id="nombre" placeholder="Nombre y apellido" name="nombre" onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)}  />
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input className="input" type="email" placeholder="Email" id="email" name="email" onInput={(e)=>this.isEmail(e.target)} onChange={(e)=>this.handleInputChange(e)}/>
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">Teléfono</label>
                                <div className="control">
                                    <input className= "input" type="tel" placeholder="Teléfono" id="telefono" name="telefono" onInput={(e)=>this.isTel(e.target)}  onChange={(e)=>this.handleInputChange(e)}/>
                                </div>
                            </div>
                            <div class="field column is-full">
                                <label class="label">Mensaje</label>
                                <div class="control">
                                    <textarea class="textarea" placeholder="¡Contanos como podemos ayudarte!" id="mensaje" name="mensaje" onInput={(e)=>this.isEmptyTextArea(e.target)} onChange={(e)=>this.handleInputChange(e)}></textarea>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div className="columns  is-variable is-multiline is-centered">
                        { (this.state.loader) ?
                            <CircularProgress color="secondary" />
                            :  <Link className="button  my-5 is-rounded" onClick={()=>{this.handleContacto()}}> Enviar </Link>
                        }
                        
                    </div>
                    
                </div>

                <>
               
               <Modal show={this.state.show} handleClose={()=>this.hideModal()}>
               <article className={(this.state.success)? "message is-success" : "message is-danger" }>
                   <div className="message-body">
                       {this.state.errorMsj}
                   </div>
               </article>
               </Modal>
               
               </>
            </div>
            
        )
    }
    
}
const mapStateToProps = state => ({
    sesion: state.Sesion
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_SESION",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps) (ContactoPersonas) 