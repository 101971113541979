import React from 'react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux";
import LaboralSubCard from "./LaboralSubCard"
import {URL} from '../../../../Constants/API'

class LaboralCard  extends React.Component{
     constructor(props){
       super(props)
       this.state ={
         rubros: [],
       }
     }
     handleCreate(e){
      e.preventDefault()
      let empresas = this.props.empresas
      let index = this.props.index
      empresas[index].jobs.push({
          curriculumID:   this.props.sesion.curriculumID,
          titulo:         "",
          empresaID:      this.props.data.ID,
          empleador:      "",
          rubroID:        1,
          telefono:       "",
          email:          "", 
          localidadID:    1,
          provinciaID:    1,
          fechaInicio:    "2000-01-01",
          fechaFin:       null,
          descripcion:    ""
      })
 
      this.props.update(empresas)
      this.forceUpdate()
    }
    componentDidMount(){
      //En caso de que no haya datos, pongo un valor "vacío"
      let empresas = this.props.empresas
      let index = this.props.index
      if(empresas[index].jobs.length === 0){
        empresas[index].jobs.push({
          curriculumID:   this.props.sesion.curriculumID,
          titulo:         "",
          empresaID:      this.props.data.ID,
          empleador:      "",
          rubroID:        1,
          telefono:       "",
          email:          "", 
          localidadID:    1,
          provinciaID:    1,
          fechaInicio:    "2000-01-01",
          fechaFin:       null,
          descripcion:    ""
        })
        this.props.update(empresas)
      }
      
      /**Cargo los datos de las empresas */
      fetch(URL +"curriculums/rubros/p/1/l/100",{ method: "GET", headers:{'Content-Type': 'application/json'}})
      .then(res => res.json())
      .then( response => {
        if(response && response.code===200){
          this.setState({rubros: response.data.rows})
        }
      })
      .catch(error => console.error("Error:", error))
    }
    
    render(){
        return(
            
            <div>
                {this.props.data.jobs.map( (data,index,) =>{
                      if(data['rubro']){ 
                        data['rubroID'] = data['rubro']['ID']
                        data['localidadID']= data['localidad']['ID']
                        data['provinciaID']= data['provincia']['ID']
                      }
                      return <LaboralSubCard empresaID={this.props.data.ID} rubros={this.state.rubros} data={data} key={"LaboralCard-"+index} index={index}></LaboralSubCard>
                   
    })}
                  
               
                  <div className="columns has-text-centered is-multiline">
                  <div className="column is-full">
                    <FontAwesomeIcon className="mr-3 cursor-pointer color-success" size="2x" icon={faPlusCircle} onClick={(e)=>this.handleCreate(e)} />
                  </div>
                  
                </div>
             </div>
        );
        
    }
}
const mapStateToProps = state => ({
  sesion: state.Sesion,
  empresas: state.Empresas
})

const mapDispatchToProps = dispatch => ({
  update(data) {
    dispatch({
      type: "UPDATE_EMPRESAS",
      data
    })
  },
})
export default connect(mapStateToProps,mapDispatchToProps)(LaboralCard)