import { createStore } from "redux"
import KingOfCookies from '../Components/KingOfCookies'
//Controlo que existan los datos se la sesion
let sesionCookies = KingOfCookies.get("sesion")
if(!sesionCookies){
    sesionCookies={
        personaID:null, 
        curriculumID: null,
        planPagoID: null}
    KingOfCookies.set("sesion",sesionCookies)
    
}
//---------------------------------//

//---------------------------------//
const initialState = {
    
    Sesion:(KingOfCookies.get("sesion"))? 
        KingOfCookies.get("sesion"): 
        {
            personaID:null,
            curriculumID: null,
            planPagoID: null
        },
    
    Personal: {},
    Idiomas: [],
    Destrezas: [],
    Estudios: [], 
    Empresas: [],
    Pdf: {},
    Pago: {
        checkoutID: null
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        //Para usar redux usamos CRUD
        //---------------------------
        //Personal
        case "UPDATE_PERSONAL":{
            return {...state,
                Personal: action.data}
        }
       //Idiomas
        case "UPDATE_IDIOMAS":{
        return {...state,
            Idiomas: action.data}
        }
        //Destrezas
        case "UPDATE_DESTREZAS":{
            return {...state,
                Destrezas: action.data}
            }
        //Estudios
        case "UPDATE_ESTUDIOS":{
            return {...state,
                Estudios: action.data}
            }
        //Empresas
        case "UPDATE_EMPRESAS":{
            return {...state,
                Empresas: action.data}
            }
        //Pdf
        case "UPDATE_PDF":{
            return {...state,
                Pdf: action.data}
            }
        //Pago
        case "UPDATE_PAGO":{
            return {...state,
                Pago: action.data}
            }
        //Sesion
        case "UPDATE_SESION":{
            KingOfCookies.set("sesion",action.data)
            return {...state,
                Sesion: action.data}
            }
        case "DELETE_SESION":{
            let sesion={
                personaID:null, 
                curriculumID: null,
                planPagoID: null}
            KingOfCookies.set("sesion",sesion)
            return {...state,
                Sesion: sesion}
        }
        default: {
            return state
        }
    }
    
    
}
export default createStore(reducer)