import { Card, CardContent } from '@mui/material'
import React from 'react'
import { useEffect,useState } from 'react'
import { URL } from '../../../Constants/API'

function ListadoCurriculum({personaId, onSelectCv}){
    const [curriculums, setCurriculums] = useState([])

    useEffect(()=>{
        let configGet = {
            method:"GET",
            headers:{'Content-Type': 'application/json'}
        }
        fetch(URL+`/curriculums/personaID/${personaId}`,configGet)
        .then(response=>{
            console.log(response)
            return response.json()
        })
        .then(curriculums=>{
            console.log(curriculums)
            setCurriculums(curriculums.data)
        })
    },[personaId])

    return(
        <>
        {
            curriculums?.map(cv=>{ 
                return(
                    (cv.file)?
                    <Card key={cv.ID} onClick={()=>onSelectCv(cv)}>
                        <CardContent>
                            <span>{cv.nombre}</span>
                        </CardContent>
                    </Card>
                    :null
                )
            })
        }
        </>
    )
}

export {ListadoCurriculum} 