import React from 'react'
import ExperienceCard from './ExperienceCard'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { faMouse } from '@fortawesome/free-solid-svg-icons'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import EstudiosCard from './Estudios/EstudiosCard'
import DestrezasCard from './Destrezas/DestrezasCard'
import IdiomasCard from './Idiomas/IdiomasCard'
import Modal from '../../../Components/Modal'
import EmpresaCard from './Laboral/EmpresaCard'

class Experience extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          redirect: false,
          show: false
        };
    }
    verificar(){
        //Modificar, que no deje enviar trabajos y estudios vacios 
        if(true){
            this.setState({redirect: true})
        }else{
            this.showModal()
        }
    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };
    render(){
        return(
            <div className="section section-2">
                <div className="container">
                    <ExperienceCard title="Experiencia laboral" icon={faBriefcase}>
                        <div className="field column is-full">
                            <label className="label">Sugerencia</label>
                            <p className="pl-1">Es muy importante que inicies con tu último trabajo u ocupación (Siempre en forma cronológica)</p>
                        </div>
                        <EmpresaCard/>
                    </ExperienceCard>
                    <ExperienceCard title="Mi educación, capacitaciones y cursos"  icon={faGraduationCap}>
                        <EstudiosCard/>
                    </ExperienceCard>
                    <ExperienceCard title="Especializacion"  icon={faMouse}>
                        <DestrezasCard/>
                    </ExperienceCard> 
                    <ExperienceCard title="Idiomas"  icon={faGlobeAmericas}>
                        <IdiomasCard/>
                    </ExperienceCard> 
                    
                    <div className="columns  is-variable is-multiline is-centered mt-5">
                        <Link className="button  is-white is-rounded mr-3 is-outlined" to="/informacion_personal">Atrás </Link>
                        <button className="button  is-link is-rounded"  onClick={() =>{this.verificar()}} >Siguiente paso </button>
                        {this.state.redirect ?  <Redirect to='/templates' /> : null}                   
                    </div>
                    
                </div>
            <>
           
            <Modal show={this.state.show} handleClose={this.hideModal}>
            <article className="message is-danger">
                <div className="message-body">
                    No se puede avanzar hasta completar <strong>todos</strong> los campos del curriculum.
                </div>
            </article>
            </Modal>
            
            </>

                
            </div>
        )
    }
    
}
const mapStateToProps = state => ({
    estudios: state.Estudios,
    empresas: state.Empresas 
    
 })

const mapDispatchToProps = dispatch => ({

    
})
export default connect(mapStateToProps,mapDispatchToProps)(Experience)