import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
class CargaArchivos extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        files: [],
      };
    }
  
    handleChange(e) {
  
      // get the files
      let files = e.target.files;
  
      // Process each file
      var allFiles = [];
      for (var i = 0; i < files.length; i++) {
  
        let file = files[i];
  
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
  
          // Make a fileInfo Object
          let fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + ' kB',
            base64: reader.result,
            file: file,
          };
  
          // Push it to the state
          allFiles.push(fileInfo);
  
          // If all files have been proceed
          if(allFiles.length == files.length){
            // Apply Callback function
            if(this.props.multiple) this.props.onDone(allFiles);
            else this.props.onDone(allFiles[0]);
          }
  
        } // reader.onload
  
      } // for
  
    }

    componentDidMount(){

    }
  
    render() {
      return (
        <>
          <div className="column  is-full">
            <div className="control">
              <input type="file" id="file" onChange={ this.handleChange.bind(this) }
              multiple={ this.props.multiple }
              accept={this.props.accept} />
              
              <Paper component="form" >
                <IconButton  aria-label="menu">
                  <MenuIcon />
                </IconButton>
                <InputBase 
                />
                <IconButton   aria-label="search">
                  <SearchIcon htmlFor="file" />
                </IconButton>
              </Paper>
              
            </div>
          </div>
        </>
      );
    }
  }
  
  CargaArchivos.defaultProps = {
    multiple: false,
  };

  export default CargaArchivos