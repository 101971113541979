import React from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { connect } from "react-redux";
import { faPenSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'
import {URL} from '../../../../Constants/API'
import Modal from '../../../../Components/Modal'
class EstudiosSubCard extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          active: !Boolean(this.props.data.ID),
          control: false,
          actualidad:  !Boolean(this.props.data.fechaFin),
          loader: false,
          estudio: this.props.data,
          show: false
        };
    }
    componentWillReceiveProps(){
        this.setState((state, props) => ({
            estudio: props.data
          }));
        this.setState((state, props) => ({
            active: !Boolean(props.data.ID)
        }));
        
    }
    
        
    
    handleClick(e){
        e.preventDefault();
        this.setState({
            active: !this.state.active
        })
       
        
    }
    async handleSave(e,i){
        this.setState({loader:true})
        e.preventDefault()
        await this.formControl()
        if(this.state.control){
            if(this.state.actualidad){
                this.setState({
                    estudio: {...this.state.estudio, fechaFin : null}
                })
            }
            if (this.state.estudio.ID) {
                /**Hago el PUT en caso de que el ID ya exista */
                let config ={
                    method: "PUT", 
                    body: JSON.stringify(this.state.estudio), 
                    headers:{'Content-Type': 'application/json'} }
                fetch(URL + "curriculums/estudios/id/"+this.state.estudio.ID, config )
                .then(res => res.json())
                .then(response => {
                    this.setState({active: false})
                    this.setState({loader:false})
                })
                
            }else{
                /**Hago el post en caso de que el ID no exista */
                console.log("POST")
                let config ={
                    method: "POST", 
                    body: JSON.stringify(this.state.estudio), 
                    headers:{'Content-Type': 'application/json'} }
                fetch(URL + "curriculums/estudios", config )
                .then(res => res.json())
                .then(response => {
                    console.log(response)
                    this.setState({
                        estudio: {...this.state.estudio, ID : response.data.ID }
                    })
                    this.setState({active: false})
                    this.setState({loader:false})
                })
                
            }
            
            
        }else{
            this.setState({show: true})
            this.setState({loader:false})
        }
        
    }
    handleDelete(e){
        this.setState({loader:true})
        e.preventDefault()
        /**Elimino el estudio */
        let config ={ 
            method: "PATCH", body: JSON.stringify({estado: "BAJA"}), 
            headers:{'Content-Type': 'application/json'}}
        /**Funcion para actualizar */
        const update = ()=>{
            let configGet = { 
                method: "GET", 
                headers:{
                    'Content-Type': 'application/json'}}
            fetch(URL+"curriculums/estudios/curriculumID/"+this.props.sesion.curriculumID, configGet)
            .then(res => res.json())
            .then(response =>{
                let rows = (response.code <400) ? response.data.rows : []
                this.props.update(rows)
            })
            .catch(error =>{
                this.setState({loader:false})
                console.error(error)
            }) 

        }
        /**Si el estudio está guardado lo elimino del server  */
        if(this.state.estudio.ID){
            fetch(URL + "curriculums/estudios/estado/id/"+this.state.estudio.ID, config )
            .then(res => res.json())
            .then(response => {
                this.setState({loader:false})
                update()  
            })
            .catch(Error => this.setState({loader:false}))
        }else{
            /**Si el estudio no está guardado, actualizo redux */
            update()
        }
        

        /**/  
    }
    
    handleInputChange (e, i) {
        
        this.setState({
            estudio: {...this.state.estudio,[e.target.name] : e.target.value}
        })
     }
     handleInputChangeCKEditor(data, i){
         this.setState({
            estudio: {...this.state.estudio,descripcion : data}
         })
         
      } 
      
    
    handleClassName (){
        let clase = "border   px-5 py-5 mb-5 level experienceSubCard"
        let hidden = "is-hidden"
        let retorno
        if (!this.state.active){
            retorno = clase
        }else{
            retorno = hidden
        }
        return retorno
    }
    isEmpty(e){
        if(!e.value){
            e.className = "input is-danger"
            this.setState({control: false})
        }else{
            e.className = "input is-success"
        }
    }
    formControl(){
        this.setState({control: true})
        this.isEmpty(document.getElementById("EstudiosSubCard__carrera--"+this.props.index))
        this.isEmpty(document.getElementById("EstudiosSubCard__institucion--"+this.props.index))
    }
    
    render(){
        return(
            <>
            <div  className={this.handleClassName()} >
                <div className="level-left">
                    <div className="level-item">
                        <p>{this.state.estudio.titulo} - {this.state.estudio.universidad} </p>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                         <FontAwesomeIcon className="is-medium edit color-success" icon={faPenSquare} onClick={(e)=>{this.handleClick(e)}} />
                    </div>
                    <div className="level-item">
                        <button className="delete has-text-warning is-medium" aria-label="delete" onClick={(e)=>this.handleDelete(e)} > </button>
                        
                    </div> 
                    
                    
                </div>
                
                
            </div>
            <div className={this.state.active ? "mb-5 border   px-5 py-5": "is-hidden"} >
            <div className="columns is-multiline">
            <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                Información academica
                            </p>
                        </header>
                <div className="card-content columns is-multiline">
                        <div className="field column is-full">
                        <label className="label">Sugerencia</label>
                            <p className="pl-1">Comenzar por lo mas reciente, o mas relevante (secundario/ terciarios /universitarios )</p>
                        </div>
                        <div className="field column is-full">
                            <label className="label">Estudios<span>*</span></label>
                            <div className="control">
                                <input className="input" value={this.state.estudio.titulo} type="text" name="titulo" id={"EstudiosSubCard__carrera--"+this.props.index} onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>{this.handleInputChange(e, this.props.index)}} placeholder="P. ej.: tecnico mecanico, tecnico quimico, tecnico en administracion, etc."/>
                            </div>
                        </div>
                        <div className="field column is-full">
                            <label className="label">Institución<span>*</span></label>
                            <div className="control">
                                <input className="input" value={this.state.estudio.universidad} type="text" name="universidad" id={"EstudiosSubCard__institucion--"+this.props.index} onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>{this.handleInputChange(e, this.props.index)}} placeholder="P. ej.: Universidad Nacional de San Luis" />
                            </div>
                        </div>
                        <div className="field column is-half">
                                <label className="label">Estado</label>
                                <div className="select">
                                    
                                    <select  name="proceso"  onChange={(e)=>{this.handleInputChange(e, this.props.index)}} value={this.state.estudio.proceso}>
                                        <option value="Cursando">Cursando</option>
                                        <option value="Culminado">Culminado</option>
                                        <option value="Abandonado/Aplazado">Abandonado/Aplazado</option>
                                    </select>
                                </div>
                            </div>
                        <div className="field column is-full">
                            <label className="label">Ciudad</label>
                            <div className="control">
                                <input className="input" value={this.state.estudio.lugar} type="text" name="lugar" onChange={(e)=>{this.handleInputChange(e, this.props.index)}} placeholder="P. ej.: San Luis" />
                            </div>
                        </div>
                        
                        <div className="field column is-one-third">
                        <label className="label">Fecha inicio<span>*</span></label>
                            <div className="control">
                                <input type="date" name="fechaInicio" value={this.state.estudio.fechaInicio} onChange={(e)=>{this.handleInputChange(e, this.props.index)}} />
                            </div>
                        </div>
                        <div className="field column is-one-third">
                            <label className="label">Fecha fin</label>
                            <div className="control">
                                <input  disabled={this.state.actualidad} type="date" name="fechaFin" value={this.state.estudio.fechaFin ? this.state.estudio.fechaFin :"this.state.laboral.fechaFin"} onChange={(e)=>{this.handleInputChange(e, this.props.index)}}/>
                            </div>
                        </div>
                        <div className="field column is-one-third">
                            <div className="control pt-5">
                                <label className="checkbox">
                                    <input checked={this.state.actualidad} type="checkbox" onChange={(e) =>{this.setState({actualidad: !this.state.actualidad})} }/>
                                    Actualidad
                                </label>
                            </div>
                        
                </div>
                        <div className="field column is-full">
                            <label className="label"> Observaciones</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data= {this.state.estudio.descripcion}
                                    onChange={(event, editor) => {
                                        this.handleInputChangeCKEditor(editor.getData(),this.props.index)
                                    }}
                                    config={{
                                        placeholder: "¿Quieres hacer una aclaracion acerca de tu estudio?",
                                        toolbar: {
                                            items: [
                                                'bold',
                                                'italic',
                                                'bulletedList',
                                                'numberedList',
                                                'undo',
                                                'redo'
                                            ]
                                        },
                                    }}  />
                        </div>
                        </div>
                        <footer className="card-footer py-5 px-5">
                        { (this.state.loader) ?
                        <CircularProgress color="secondary" />
                        :  <><button className="button is-success mr-3" onClick={(e)=>this.handleSave(e, this.props.index)} > Guardar</button>
                        <button className="button is-danger" onClick={(e)=>this.handleDelete(e)} >Eliminar </button></>
                    }
                            
                        </footer>
                    </div>
                    </div>
            </div>
    
            <Modal show={this.state.show} handleClose={()=>this.setState({show: false})}>
                <article className="message is-danger">
                    <div className="message-body ">
                        Error - no se completó la información correctamente
                    </div>
                </article>
            </Modal>
            </>
        )
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion
  })

const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_ESTUDIOS",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps)(EstudiosSubCard) 