import { Button, Chip, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Box, FormControlLabel, Switch, FormHelperText } from '@mui/material'
import React from 'react'
import {useState, useEffect} from 'react'
import { URL } from '../../../Constants/API'
import '../../../Sass/formularioPostulacion.scss'
import Modal from '@mui/material/Modal';
import { ListadoCurriculum } from './ListadoCurriculums'
import { ResponsePostulacion } from './responsePostulacion'



function FormPostulacion({personaID,avisoID,tituloAviso}){
    const [provincias,setProvincias] = useState([])
    const [localidades,setLocalidades] = useState([])
    const [formState, setFormState] = useState({
        provinciaID:'',
        localidadID:'',
        nombre:'',
        email:'',
        telefono:'',
        genero:'',
        snWhatsapp:true
    })

    const [formValidation, setFormValidation] = useState({
        provinciaID:'',
        localidadID:'',
        nombre:'',
        email:'',
        telefono:'',
        genero:''
    })

    const [curriculum,setCurriculum] = useState()
    const [openModal,setOpenModal] = useState(false)
    const [formEnviado,setFormEnviado] = useState(false)
    const [validFile, setValidFile] = useState(false)
    const [validForm, setValidForm] = useState(false) 

    const [stateEnvioForm, setStateEnvioForm] = useState('')

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
      

    useEffect(()=>{
        fetch(URL+'extras/provincias')
        .then(response=>{
            return response.json()
        })
        .catch(error => console.error('Error:', error))
        .then(provincias=>{
            if(provincias.code==200){
                setProvincias(provincias.data)
            }
        })
    },[])

    useEffect(()=>{
        if(personaID!==null){
            fetch(URL+'personas/for-cv/id/'+personaID)
            .then(response=>{
                return response.json()
            })
            .then(persona=>{
                console.log(persona)
                if(persona.code==200){
                    cargarLocalidades(persona.data.provinciaID)
                    let dataPersona = {
                        nombre: persona.data.nombre+' '+persona.data.apellido,
                        email: persona.data.email,
                        genero: persona.data.genero,
                        telefono: persona.data.telefono,
                        provinciaID: persona.data.provinciaID,
                        localidadID: persona.data.localidadID,
                        snWhatsapp:true
                    }
                    setFormState(dataPersona)
                }
            })
        }
    },[personaID])

    function handleChange(evt) {
        console.log('setFormState',formState)
        /*
          evt.target es el elemento que ejecuto el evento
          name identifica el input y value describe el valor actual
        */
        const { target } = evt;
        const { name, value, checked } = target;
        /*
          Este snippet:
          1. Clona el estado actual
          2. Reemplaza solo el valor del
             input que ejecutó el evento
        */
        const newValues = {
          ...formState,
          [name]: name!=='snWhatsapp'? value : checked,
        };
        // Sincroniza el estado de nuevo
        setFormState(newValues);
        //cargo localidades si cambio el select de provincia
        if(name=='provinciaID'){
            cargarLocalidades(value)
        }
      }

    const handleOpen = ()=>{
        setOpenModal(true)
    }

    const handleClose = ()=>{
        setOpenModal(false)
    }

    function validarForm(){
        setFormValidation({
            provinciaID:'',
            localidadID:'',
            nombre:'',
            email:'',
            telefono:'',
            genero:''
        })
        let valid = true        
        if(!formState.nombre.trim()){
            setFormValidation(formValidation=>({...formValidation,nombre:'El nombre es requerido'}))
            console.log(formValidation)
            valid = false
        }
        if(!formState.localidadID){
            setFormValidation(formValidation=>({...formValidation,localidadID:'Debes seleccionar una localidad'}))
            console.log(formValidation)
            valid = false
        }
        if(!formState.provinciaID){
            setFormValidation(formValidation=>({...formValidation,provinciaID:'Debes seleccionar una localidad'}))
            valid = false
        }
        if(!formState.email.trim()){
            setFormValidation(formValidation=>({...formValidation,email:'El email es requerido'}))
            valid = false
        }
        if(!(/\S+@\S+\.\S+/.test(formState.email))){
            setFormValidation(formValidation=>({...formValidation,email:'Coloca un email valido'}))
            valid = false
        }
        if(/[-+]/.test(formState.telefono)){
            setFormValidation(formValidation=>({...formValidation,telefono:'El numero de telefono no puede tener guiones ni el signo +'}))
            valid = false
        }
        if(/\s/.test(formState.telefono)){
            setFormValidation(formValidation=>({...formValidation,telefono:'El numero de telefono no puede tener espacios'}))
            valid = false
        }
        if(/^0/.test(formState.telefono)){
            setFormValidation(formValidation=>({...formValidation,telefono:'Coloca el código de area sin 0'}))
            valid = false
        }
        if(formState.telefono.length<10){
            setFormValidation(formValidation=>({...formValidation,telefono:'El numero de telefono es incorrecto'}))
            valid = false
        }
        if(!formState.genero){
            setFormValidation(formValidation=>({...formValidation,genero:'Debes seleccionar un genero'}))
            valid = false
        }
        setValidForm(valid)
        console.log(formValidation)
        console.log(formState)
    }

      function cargarLocalidades(provinciaID){
        fetch(URL+`extras/localidades/provinciaID/${provinciaID}`)
        .then(response=>{
            return response.json()
        })
        .catch(error => console.error('Error:', error))
        .then(localidades=>{
            console.log(localidades)
            if(localidades.code==200){
                setLocalidades(localidades.data)
            }
        })
      }

      function subirPdf(event){
        let curriculum = new Object()
        const fileList = event.target.files;
        console.log(fileList)
        if(fileList[0].type==="application/pdf"){
            setValidFile(true)
            curriculum.nombreArchivo = fileList[0].name
            var reader = new FileReader()
            reader.readAsDataURL(fileList[0])
            reader.onload= function(){
                curriculum.base64 = reader.result
            }
            reader.onerror = function(error){
                console.log(error)
            }
            console.log(fileList);
            setCurriculum(curriculum)
        }else{
            setValidFile(false)
        }
      }

      const selectFile= function(){
        const fileInput = document.getElementById('selectorPdf')
        fileInput.click()
      }

      const enviarPostulacion = function(){
        validarForm()
        if(validFile && validForm){

            let body = {
                aviso:{
                    ID: avisoID,
                    titulo: tituloAviso
                },
                provinciaID: formState.provinciaID,
                localidadID: formState.localidadID,
                personaID: personaID,
                nombre: formState.nombre,
                email: formState.email,
                telefono: /^594/.test(formState.telefono)? formState.telefono : '594'+formState.telefono,
                genero: formState.genero,
                snWsp: formState.snWhatsapp
            }
    
            if(curriculum.base64){
                body.base64 = curriculum.base64
            }else{
                body.cv = curriculum.cv
            }
    
            console.log(body)
    
            let config = {
                method: 'POST',
                body : JSON.stringify(body),
                headers:{'Content-Type':'application/json'}
            }
    
            fetch(`${URL}avisos/postulantes`,config).then(response=>{
                return response.json()
            }).then(result=>{
                console.log(result)
                if(result.code===201){
                    setStateEnvioForm('success')
                }
                if(result.code === 409){
                    if(result.data.duplicate === 'uq_aviso_email'){
                        setStateEnvioForm('duplicate_email')
                    }
                }
                setFormEnviado(true)
            })
        }else{
            //no se esta cargando un archivo valido
        }
      }

      function handleSelectedCv(cv){
        console.log(cv)
        let curriculum = new Object()
        curriculum.cv = cv.file.slice(1)
        curriculum.nombreArchivo = cv.nombre+" "+cv.apellido+"-"+cv.createdAt
        setCurriculum(curriculum)
        setValidFile(true)
        setOpenModal(false)
      }

    return(
        <>
            <Modal
                hideBackdrop
                open={openModal}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={style}>
                <h2 id="child-modal-title">Seleccionar un curriculum</h2>
                <ListadoCurriculum personaId={personaID} onSelectCv={(cv)=>handleSelectedCv(cv)}></ListadoCurriculum>
                <Button onClick={handleClose}>Cerrar</Button>
                </Box>
            </Modal>
            {(!formEnviado)?
            <>
            <div className='section-title-container'>
                <Divider textAlign='center'>
                    <Chip label="Datos Personales"/>
                </Divider>
            </div>
            <div className='form-row'>
                <div className='form-col-50'>
                <FormControl fullWidth
                    error={!!formValidation.provinciaID}
                >
                    <InputLabel id='provincias-label'>Provincia de residencia</InputLabel>
                    <Select
                        labelId='provincias-label'
                        name='provinciaID'
                        label="Provincia de residencia"
                        value={formState.provinciaID}
                        onChange={handleChange}
                    >
                        {
                            provincias.map(prov=>(<MenuItem key={prov.ID} value={prov.ID}>{prov.nombre}</MenuItem>))
                        }
                    </Select>
                    <FormHelperText>{formValidation.provinciaID}</FormHelperText>
                </FormControl>
                </div>
                <div className='form-col-50'>
                <FormControl fullWidth
                    error={!!formValidation.localidadID}
                >
                    <InputLabel id='localidad-label'>Localidad de residencia</InputLabel>
                    <Select
                        labelId='localidad-label'
                        name='localidadID'
                        label="Localidad de residencia"
                        value={formState.localidadID}
                        onChange={handleChange}
                    >
                        {
                            localidades.map(loc=>(<MenuItem key={loc.ID} value={loc.ID}>{loc.nombre}</MenuItem>))
                        }
                    </Select>
                    <FormHelperText>{formValidation.localidadID}</FormHelperText>
                </FormControl>
                </div>
            </div>
            <div className='form-row'>
                <div className='form-col-50'>
                    <FormControl fullWidth
                        error={!!formValidation.nombre}
                    >
                        <TextField name='nombre' value={formState.nombre} label="Nombre y apellido" variant='outlined' onChange={handleChange}></TextField>
                        <FormHelperText>{formValidation.nombre}</FormHelperText>
                    </FormControl>
                </div>
                <div className='form-col-50'>
                    <FormControl fullWidth
                        error={!!formValidation.email}
                    >
                        <TextField name='email' value={formState.email} label="Email" variant='outlined' onChange={handleChange}></TextField>
                        <FormHelperText>{formValidation.email}</FormHelperText>
                    </FormControl>
                </div>
            </div>
            <div className='form-row'>
                <div className='form-col-50'>
                    <FormControl fullWidth
                        error={!!formValidation.telefono}                    
                    >
                        <TextField type='number' name='telefono' value={formState.telefono} label="Teléfono (código de area y numero)" variant='outlined' onChange={handleChange}></TextField>
                        <FormHelperText>{formValidation.telefono}</FormHelperText>
                        <FormControlLabel  control={<Switch name='snWhatsapp' checked={formState.snWhatsapp} onChange={handleChange}></Switch>} label="Permitir que se comuniquen a este numero por whatsapp"></FormControlLabel>
                    </FormControl>
                </div>
                <div className='form-col-50'>
                    <FormControl fullWidth
                        error={!!formValidation.genero}
                    >
                        <InputLabel id='genero-label'>Genero</InputLabel>
                        <Select
                            labelId='genero-label'
                            name='genero'
                            label="Genero"
                            value={formState.genero}
                            onChange={handleChange}
                        >
                            <MenuItem value="M">Masculino</MenuItem>
                            <MenuItem value="F">Femenino</MenuItem>
                        </Select>
                        <FormHelperText>{formValidation.genero}</FormHelperText>
                    </FormControl>
                </div>
            </div>
            <div className='section-title-container'>
                <Divider textAlign='center'>
                    <Chip label="Curriculum"/>
                </Divider>
            </div>
            <div className='form-row'>
                <div className='button-group'>
                    <input id='selectorPdf' accept='.pdf'onChange={($event)=>subirPdf($event)} type='file' hidden></input>
                    {(personaID)?
                        <Button className='button-file' variant="outlined" onClick={handleOpen}>seleccionar de mi curriculum</Button>
                        :null
                    } 
                    <Button className='button-file' onClick={selectFile} variant="outlined">Subir archivo PDF</Button>             
                </div>
            </div>
            <div className='form-row'>
                <div className='curriculum-container'>
                    {
                        (curriculum)?
                            <span>{curriculum.nombreArchivo}</span>
                        : <span>No has cargado un curriculum todavía (solo se permiten archivos pdf)</span>
                    }
                </div>
                
            </div>
            {
                (!validFile)?
                <span>Solo se admiten archivos de tipo PDF</span>
                :null
            }
            <div className='form-row'>
                <div className='button-group'>
                    <Button variant='contained' onClick={enviarPostulacion}>Enviar postulación</Button>
                </div>
            </div>
            </>
            : <ResponsePostulacion estado={stateEnvioForm}/>
            }
        </>
    )
}

export {FormPostulacion}