import React from 'react'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const Check = ()=>{
    return (<td style={Object.assign({}, styles.td_icon, styles.icon_check )}><FontAwesomeIcon icon={faCheck}/></td>)
}

const CheckMovil = ()=>{
    return (<div style={Object.assign({}, styles.td_icon, styles.icon_check )}><FontAwesomeIcon icon={faCheck}/></div>)
}

const Times = ()=>{
    return (<td style={Object.assign({}, styles.td_icon, styles.icon_times)}><FontAwesomeIcon icon={faTimes}/></td>)
}

const TimesMovil = ()=>{
    return (<div style={Object.assign({}, styles.td_icon, styles.icon_times)}><FontAwesomeIcon icon={faTimes}/></div>)
}
const Title = (props)=>{
    return (
        <td>
            <label className="label label-2">{props.name} </label>
            <p>{props.description}</p>    
        </td>
        )

}

function isMobile(){
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}

const Precios = () =>{
    return (
        <>
        {(!isMobile())?
        <div className="card mx-6 mt-6 row col-12" style={{width:'90%'}}>
            <div className="card-body">
                <table className="table is-narrow is-fullwidth">
                    <tbody>
                        <tr>
                            <td><h2 className="bold">Funcionalidades</h2></td>
                            <td style={styles.td_icon}> <label className="label label-2">Gratuito</label></td>
                            <td style={styles.td_icon}> <label className="label label-2">Premium</label></td>
                        </tr>
                        <tr>
                            <Title name="Cargar tus datos laborales"></Title>
                            <Check/>
                            <Check/>
                        </tr>
                        <tr>
                            <Title name="Armar curriculum" ></Title>
                            <Check/>
                            <Check/>
                        </tr>

                        <tr>
                            <Title name="Generación y visualización"></Title>
                            <Check/>
                            <Check/>
                        </tr>
                        <tr>
                            <Title name="Guardar tus archivos laborales (Certificados/Analíticos/Carta de presentación/Etc)"></Title>
                            <Check/>
                            <Check/>
                        </tr>
                        <tr>
                            <Title name="Descargar tu CV en formato PDF" ></Title>
                            <Times/>
                            <Check/>
                        </tr>
                        <tr>
                            <Title name="Envío de CV desde nuestra plataforma"></Title>
                            <Times/>
                            <Check/>
                        </tr>
                        
                        

                        <tr>
                            <td></td>
                            <td className="has-text-centered"><h2 className="bold mx-6 my-6 ">GRATUITO</h2></td>
                            <td className="has-text-centered">
                                <h2 className="bold mx-6 my-6 ">199 <br/><label  style={styles.price_sufix}>ARG/Uso de la plataforma por 30 días</label></h2>
                                <Link className="button my-5 is-rounded" to="pagos" > Comprar ahora </Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        

        :
        <TableContainer>
            <Table style={{maxWidth:'100%'}}>
                <TableHead>
                <TableRow> 
                    <TableCell><td style={styles.td_icon}> <label className="label label-2">Gratuito</label></td></TableCell>
                    <TableCell><td style={styles.td_icon}> <label className="label label-2">Premium</label></td></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell colspan="2">
                            <Title name="Cargar tus datos laborales"></Title>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colspan="1"><CheckMovil></CheckMovil></TableCell>
                        <TableCell colspan="1"><CheckMovil></CheckMovil></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colspan="2">
                            <Title name="Armar curriculum"></Title>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><CheckMovil></CheckMovil></TableCell>
                        <TableCell><CheckMovil></CheckMovil></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colspan="2">
                            <Title name="Generación y visualización" ></Title>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><CheckMovil></CheckMovil></TableCell>
                        <TableCell><CheckMovil></CheckMovil></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colspan="2">
                            <Title name="Guardar tus archivos laborales (Certificados/Analíticos/Carta de presentación/Etc)" ></Title>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><CheckMovil></CheckMovil></TableCell>
                        <TableCell><CheckMovil></CheckMovil></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colspan="2">
                            <Title name="Descargar tu CV en formato PDF"></Title>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TimesMovil></TimesMovil></TableCell>
                        <TableCell><CheckMovil></CheckMovil></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colspan="2">
                            <Title name="Envío de CV desde nuestra plataforma"></Title>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TimesMovil></TimesMovil></TableCell>
                        <TableCell><CheckMovil></CheckMovil></TableCell>
                    </TableRow>

                    <TableRow>
                            <TableCell ><h5 className="bold mx-6 my-6 ">GRATUITO</h5></TableCell>
                            <TableCell className="has-text-centered">
                                <h5 className="bold mx-6 my-6 ">199 <br/><label>ARG/Uso de la plataforma por 30 días</label></h5>
                                <Link className="button mb-3 is-rounded" to="pagos" > Comprar ahora </Link>
                            </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        }
        </>
    )
}

const styles = {
    td_icon:{
        textAlign: 'center',
        padding: '30px 15px'

    },
    icon_check:{
        color: '#3F51B5 ',
        background: 'rgba(63, 81, 181, 0.1)'
    },
    icon_times:{
        color:'#3f51b57d '
    },
    price_sufix:{
        fontSize: 10,
    }
}
export default Precios