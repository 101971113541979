/**URL DEL SERVIDOR */
//PRODUCCION
const URL = "https://micurriculum.com.ar/api/"

//DESARROLLO
//const URL = "http://repo.ifelse.com.ar:9001/api/"


/**HOST DEL SERVIDOR */
//PRODUCCION
const HOST = "https://micurriculum.com.ar"

//DESARROLLO
//const HOST = "http://repo.ifelse.com.ar:9001"

export  {URL, HOST}