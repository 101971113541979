import React from 'react'
import { Link, Redirect } from 'react-router-dom';
import sha3 from 'crypto-js/sha3'
import Modal from  '../../Components/Modal'
import { connect } from "react-redux"
import {URL} from '../../Constants/API'
import CircularProgress from '@material-ui/core/CircularProgress'
import ReactGA from 'react-ga';
import Cleave from 'cleave.js/react';

class Registro extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          registro: {
            provinciaID:1,  
            localidadID:1,
            nombre:"",
            cuil:"",
            apellido:"",
            user:"",
            fechaNac:"",
            password:"",
            password_2:"",
            email:"",
            telefono:"",
            genero:"M",
            tokenGoogle:""
            },
          success: false,
          failure: false,
          control: true,
          show: false,
          provincias: [],
          localidades: [],
          loader: false,
          errorMsj: ""
        };
    }
    
    
    handleInputChange =(event) =>{
        this.setState({ registro : {...this.state.registro,
            [event.target.name] : event.target.value}
            
        })
        if(event.target.name==="provinciaID"){
            this.setState({localidades:[
                {
                    "ID": 1,
                    "nombre": "Cargando..."
                }
            ]})
            let config = { 
                method: "GET", 
                headers:{
                    'Content-Type': 'application/json'}
            }
            fetch(URL+"extras/localidades/provinciaID/"+event.target.value, config)
            .then(res => res.json())
            .then(response =>{
                this.setState({localidades: response.data})

            })
            .catch(error => console.error(error))
        }
    }
    

    
    encriptar = (pass) =>{
        let hash = sha3(pass).toString()
        let sal= ''
        for(let i=0;i<6;i++){
            sal = sal + Math.round(Math.random()*9)
        }
        let hash_salado = sal + hash
        let hash_reforzado = sha3(hash_salado).toString() 
        let enviar = sal + hash_reforzado
        return enviar
    }

    
    componentDidMount() {
        //Google Analitycs
        ReactGA.initialize('UA-142549046-23');
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(window.location.pathname + window.location.search)
        /**Cargo los datos de una persona */
        let config = { 
            method: "GET", 
            headers:{
                'Content-Type': 'application/json'}
        }
        
        /**Cargo los datos de las provincias */
        this.setState({provincias:[{
            "ID": null,
            "nombre": "Cargando..."}]})
        this.setState({localidades:[{
            "ID": null,
            "nombre": "Cargando..."}]})

        fetch(URL+"extras/provincias", config)
        .then(res => res.json())
        .then(response =>{
            this.setState({provincias: response.data})
            /**Cargo los datos de las localidades*/
            fetch(URL+"extras/localidades/provinciaID/"+1, config)
            .then(res => res.json())
            .then(response =>{
                this.setState({localidades: response.data})

            })
            .catch(error => console.error(error))
        })
        .catch(error => console.error(error))
       
    }
    handleRegistro = async () =>{
        await this.setState({control:true})
        this.isEmpty(document.getElementById("nombre"))
        this.isEmpty(document.getElementById("apellido"))
        this.isCuil(document.getElementById("cuil"))
        this.isEmpty(document.getElementById("fechaNac"))
        this.isEmpty(document.getElementById("user"))
        this.isEmail(document.getElementById("email"))
        this.isTel(document.getElementById("telefono"))
        this.isEmpty(document.getElementById("genero"))
        this.isEmpty(document.getElementById("localidadID"))
        this.isEmpty(document.getElementById("provinciaID"))
        this.isPassword()
        if(this.state.control){
            this.setState({loader: true})
            let pass =this.encriptar(this.state.registro.password)
            let data = {
                "provinciaID":this.state.registro.provinciaID,
                "localidadID":this.state.registro.localidadID,
                "idPP": 318,
                "nombre":this.state.registro.nombre +" "+ this.state.registro.apellido,
                "cuil":this.state.registro.cuil,
                "fechaNac":this.state.registro.fechaNac,
                "user":this.state.registro.user,
                "password":pass,
                "email":this.state.registro.email,
                "telefono":this.state.registro.telefono,
                "genero":this.state.registro.genero,
                "curriculumID":this.props.sesion.curriculumID,
                "tokenGoogle":""
            }
            let config ={
                method: 'POST', 
                body: JSON.stringify(data),
                headers:{'Content-Type': 'application/json'}}

            fetch(URL+"personas", config)
            .then(res =>res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
                this.setState({loader: false})
                console.log("Registro: ", response)
                if(response.code>=400){
                    this.setState({success: false})
                    this.setState({failure: true})
                    if(response.code === 409){
                        if(response.msg.search('mail')!==-1){
                            this.showModal("Email repetido.")
                        }else{
                            this.showModal("Usuario repetido.")
                        }
                        
                    }else{
                        this.showModal("No se puede avanzar hasta completar correctamente todos los campos del formulario.")
                    }
                    
                }else{
                    console.log(response)
                    this.saveCV(response.ID)
                    this.setState({success: true})
                    this.setState({failure: false})
                    this.props.update({...this.props.sesion,
                        personaID: response.ID,
                        planPagoID: response.planPagoID})
                }
            });
        }else{
            this.showModal("No se puede avanzar hasta completar correctamente todos los campos del formulario.")
        }
    }
    saveCV=(personaID)=>{
        let body= { personaID: personaID}
        let config = { 
            method: 'PATCH', 
            body: JSON.stringify(body),
            headers:{'Content-Type': 'application/json'}}
        if(this.props.sesion.curriculumID){
            fetch(URL + "curriculums/persona/id/"+this.props.sesion.curriculumID, config)
            .then(res =>res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {console.log(response)});
        }
        

    } 
    isEmpty = (e)=>{
        if(!e.value){
            e.className = "input is-danger"
            this.setState({control: false})
        }else{
            e.className = "input is-success"
        }
    }
    isPassword = ()=>{
        if(document.getElementById("password").value === document.getElementById("password_2").value && document.getElementById("password").value!==""){
            
            document.getElementById("password_2").className = "input is-success"
        }else{
            
            document.getElementById("password_2").className = "input is-danger"
            this.setState({control: false})
        }
    }
    isCuil = (e)=>{
        
        let regex = /^([0-9]{2}-[0-9]{8}-[0-9]{1})$/;
        
        if(regex.test(e.value)){
            e.className = "input is-success"
        }else{
            e.className = "input is-danger"
            this.setState({control: false})
        }
    }
    isEmail= (e) =>{
        let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(regex.test(e.value)){
            e.className = "input is-success"
        }else{
            e.className = "input is-danger"
            this.setState({control: false})
        }
    }
    isTel= (e) =>{
        let regex = /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/;
        if(regex.test(e.value)){
            e.className = "input is-success"
        }else{
            e.className = "input is-danger"
            this.setState({control: false})
        }
    }
    showModal = (msj) => {
        this.setState({show: true})
        this.setState({errorMsj: msj})
    }

    hideModal = () => {
        this.setState({show: false})
    }
    render(){
        return(
            <div className="section section-2">
                <div className="container">
                <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                Registro de usuario
                            </p>
                        </header>
                        <div className="card-content columns is-multiline">
                           
                            <div className="field column is-full">
                                <label className="label">Nombre</label>
                                <div className="control">
                                    <input className= "input" type="text" id="nombre" placeholder="Nombre" name="nombre" onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)}  />
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">Apellido</label>
                                <div className="control">
                                    <input className= "input" type="text" id="apellido"  placeholder="Apellido" name="apellido" onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)}/>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">CUIL</label>
                                <div className="control">
                                <Cleave placeholder="Ingresar CUIL"
                                    className="input" id="cuil" type="text" placeholder="20-38926306-3" name="cuil" onInput={(e)=>this.isCuil(e.target)} onChange={(e)=>this.handleInputChange(e)}
                                        options={{
                                            numericOnly: true,
                                            delimiters: ['-', '-'],
                                            blocks: [2, 8, 1],
                                        }
                                        }/>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Fecha de nacimiento <span>*</span></label>
                                <div className="control">
                                    <input id="fechaNac" type="date" name="fechaNac" onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)}/>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Usuario</label>
                                <div className="control">
                                    <input className= "input" type="text" id="user" placeholder="Usuario" name="user" onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)}/>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Género</label>
                                <div className="select">
                                    <select name="genero" id="genero" onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)}>
                                        <option value="M">Hombre</option>
                                        <option value="F">Mujer</option>
                                        <option value="I">Otro</option>
                                    </select>
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input className="input" type="email" placeholder="Email" id="email" name="email" onInput={(e)=>this.isEmail(e.target)} onChange={(e)=>this.handleInputChange(e)}/>
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">Teléfono</label>
                                <div className="control">
                                    <input className= "input" type="tel" placeholder="Teléfono" id="telefono" name="telefono" onInput={(e)=>this.isTel(e.target)}  onChange={(e)=>this.handleInputChange(e)}/>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Provincia</label>
                                <div className="select">
                                    <select id="provinciaID" name="provinciaID"  onInput={(e)=>this.isEmpty(e.target)}  onChange={(e)=>this.handleInputChange(e)}>
                                        {this.state.provincias.map(element=>(<option value={element.ID}>{element.nombre}</option>))}
                                    </select>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Localidad</label>
                                <div className="select">
                                    <select id="localidadID" name="localidadID"  onChange={(e)=>this.handleInputChange(e)} onInput={(e)=>this.isEmpty(e.target)}>
                                     {this.state.localidades.map(element=>(<option value={element.ID}>{element.nombre}</option>))}
                                    </select>
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">Contraseña</label>
                                <div className="control">
                                    <input className="input" type="password" placeholder="Contraseña" name="password" id="password" onInput={(e)=>{this.isPassword(); this.isEmpty(e.target);}}  onChange={(e)=>this.handleInputChange(e)}/>
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">Confirmar contraseña</label>
                                <div className="control">
                                    <input className="input" type="password" placeholder="Contraseña" name="password_2" id="password_2"  onInput={(e)=>this.isPassword()}  onChange={(e)=>this.handleInputChange(e)}/>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div className="columns  is-variable is-multiline is-centered">
                        { (this.state.loader) ?
                            <CircularProgress color="secondary" />
                            :  <Link className="button  my-5 is-rounded" onClick={()=>{this.handleRegistro()}}  >Registrarse </Link>
                        }
                        
                        {(this.state.success || this.props.sesion.personaID) ?  <Redirect to='/curriculums' /> : null}
                    </div>
                    
                </div>

                <>
               
               <Modal show={this.state.show} handleClose={()=>this.hideModal()}>
               <article className="message is-danger">
                   <div className="message-body">
                       {this.state.errorMsj}
                   </div>
               </article>
               </Modal>
               
               </>
            </div>
            
        )
    }
    
}
const mapStateToProps = state => ({
    sesion: state.Sesion
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_SESION",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps) (Registro) 