import React from 'react'
import logo from '../../Assets/icoifelse .png'
const Footer = () =>{
    return (
        <>
        <footer className="footer">
            <div className="content has-text-centered has-text-white">
                <p>
                © Copyright <strong> <a href="https://ifelsesl.com/" rel="noopener" target="_blank" arial-label="IfElse">IfElse</a></strong>.Todos los derechos reservados
                Diseñado por <a target="_blank" href="https://ifelsesl.com/" rel="noopener" arial-label="IfElse"> <img src={logo} alt="icon IfElseSL- https://ifelse.com.ar"/></a>
                </p>
            </div>
        </footer>
        </>
    )
}
export default Footer