import React from 'react'
import {URL} from './API'
import { connect } from "react-redux"

class Deuda extends React.Component {
    
    componentDidMount(){
        if(this.props.sesion.personaID){
            let config = { 
                method: 'GET',
                headers:{'Content-Type': 'application/json'}}
            fetch(URL + "personas/deuda/id/"+this.props.sesion.personaID+"/planPagoID/"+this.props.sesion.planPagoID, config)
            .then(res =>res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
                console.log("Deuda: ", response)
                if(response){
                    this.props.update({...this.props.pago, checkoutID: response.checkoutID })
                }
            })
            }

        }
        
    
    render(){
        return(
            <>

            </>
        )
    }
}

const mapStateToProps = state => ({
    sesion: state.Sesion,
    pago: state.Pago
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_PAGO",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps)( Deuda)