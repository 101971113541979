import React from 'react'
import {Route, Redirect} from "react-router-dom";
const PrivateRoute = (props) => { 
 //path ---> Ruta protegida   
 //redirect ---> de donde viene 
 //auth ----> bool de ruta protegida
  if (props.auth) {
    return( <Route exact path={props.path} >
            {props.children}
       </Route>) 
  }
    return <Redirect to={props.redirect} />
  

  
}
export default PrivateRoute