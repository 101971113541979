import React from 'react';
import {Redirect} from 'react-router-dom';
import { connect } from "react-redux";
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Modal from '../../../Components/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import FileBase64 from '../../../Components/FileBase64'
import {URL, HOST} from '../../../Constants/API'
import Cleave from 'cleave.js/react';
class PersonalForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          control: false,
          success: false,
          show: false,
          uploading: false,
          loader: false,
          personal: {
            provinciaID:    1,
            localidadID:    1, 
            fechaNac:       "2000-01-01",
          },
          provincias: [],
          localidades: [],
          provinciaID: 1,
          files: [],
          image: null,
          newImage: false,
          form: {},
          loaderAPI: false
        };
        
    }
    getFiles(files){
        this.setState({ files: files })
        this.setState({image:this.state.files.base64 })
        this.setState({newImage: true})
    }

    handleInputChange =(event) =>{
        console.log("Campo: ", event.target.name)
        console.log("Valor: ", event.target.value)
        if(event.target.name !== 'nombre' && event.target.name !== 'apellido'){
            this.setState({
                personal: {...this.state.personal, [event.target.name] : event.target.value }
            })
            if(event.target.name=="provinciaID"){
                this.setState({localidades:[
                    {
                        "ID": 1,
                        "nombre": "Cargando..."
                    }
                ]})
                let config = { 
                    method: "GET", 
                    headers:{
                        'Content-Type': 'application/json'}
                }
                fetch(URL+"extras/localidades/provinciaID/"+event.target.value, config)
                .then(res => res.json())
                .then(response =>{
                    this.setState({localidades: response.data})
    
                })
                .catch(error => console.error(error))
            }
        }
        
    }

    handleInputChangeCKEditor= (perfilData) =>{
        this.setState({
            personal: {...this.state.personal, perfil : perfilData }
        })
    }
    
    async handleSubmit() {
        this.setState({loader:true})
        
        await this.setState({control: true})
       
        this.isEmpty(document.getElementById("nombre"))
        this.isEmpty(document.getElementById("apellido"))
        this.isEmpty(document.getElementById("genero"))
        this.isEmpty(document.getElementById("direccion"))
        this.isSuccess(document.getElementById("web"))
        this.isEmpty(document.getElementById("cp"))
        this.isCuil(document.getElementById("cuil"))
        this.isEmail(document.getElementById("email"))
        this.isTel(document.getElementById("telefono"))
        this.isCkEditor(this.state.personal.perfil)

        if(this.state.control){

                /**Cambio los valores de localidadID y provinciaID a Int y saco los guiones del cuit */
                this.setState({
                    personal:{
                        ...this.state.personal,
                        localidadID: parseInt(this.state.personal.localidadID),
                        provinciaID: parseInt(this.state.personal.provinciaID),
                        cuil: parseInt(this.state.personal.cuil.replaceAll("-",""))
                    }})
                //--------------------------------------------------------

                if(!this.props.sesion.cuit){
                    console.log('agregando el cuil a la persona')
                    this.patchCuil(this.state.personal.cuil).then()
                }

                if(this.props.sesion.curriculumID){
                    this.put(this.state.personal).then()
                }else{
                    this.setState({personal:{...this.state.personal, personaID: this.props.sesion.personaID  }})
                    this.post(this.state.personal).then()
                }

        }else{
            this.showModal()
            this.setState({loader:false})
        }
        
    }

    async patchCuil(cuil){
        let config = {
            method: "PUT", 
            body: JSON.stringify({"cuil":cuil}),
            headers:{'Content-Type': 'application/json'}
        }
        fetch(`${URL}personas/id/${this.props.sesion.personaID}`,config)
        .then(res=>res.json())
        .catch(error=>console.log('Error:',error))
        .then(response=> {
            console.log(response)
            if(response.code ===201){
                this.props.update({...this.props.sesion,cuil:cuil})
            }
        })
    }

    async put(data){
        console.log(data)
        let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        if(this.state.newImage){
            /**Guardo imagen */
            fetch(URL + "curriculums/imgs/id/"+this.props.sesion.curriculumID,{ method: "POST", body: JSON.stringify({"base64":this.state.image}), headers:{'Content-Type': 'application/json'} })
            .then(res =>res.json())
            .catch(error => console.error('Error:', error))
            .then(response => console.log(response))
        }
        
        /**Guardo información de perfil*/
        fetch(URL + "curriculums/id/"+this.props.sesion.curriculumID, { method: "PUT", body: JSON.stringify(data), headers:{'Content-Type': 'application/json'} })
                .then(res =>res.json())
                .catch(error => console.error('Error:', error))
                .then(response => {
                    this.setState({loader:false})
                    if(response.code< 400){
                        this.setState({success:true})
                        console.log("Exito", response)
                        
                    }else{
                        console.log("FRACASO", response)
                    }
                    
                    
                })
    }
    async post(data){
        
        /**Guardo información de perfil*/
        fetch(URL + "curriculums", { method: "POST", body: JSON.stringify(data), headers:{'Content-Type': 'application/json'} })
        .then(res =>res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {    
            this.setState({loader:false})
            console.log(response)    
            if(response.code < 400){
                    console.log('updateando la sesion',this.props.sesion)
                    this.props.update({...this.props.sesion,
                    curriculumID: response.data.ID,
                    cuil:this.state.personal.cuil})
                    this.setState({success:true})
                    if( this.state.newImage){
                        /**Guardo imagen */
                        fetch(URL + "curriculums/imgs/id/"+this.props.sesion.curriculumID, { method: "POST", body: JSON.stringify({"base64":this.state.image}), headers:{'Content-Type': 'application/json'} })
                        .then(res =>res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => console.log(response))
                    }
                     
                }
            })
    }
    
    
    
    componentDidMount() {
        window.scrollTo(0, 0);
        
        let config = { 
            method: "GET", 
            headers:{
                'Content-Type': 'application/json'}
        }

        //Cargo automaticamente los datos de una persona
        if(this.props.sesion.personaID){
            let personaID = this.props.sesion.personaID
            console.log(personaID)
            fetch(URL+"personas/for-cv/id/"+personaID,config)
            .then(res => res.json())
            .then(response => {
                console.log('info de persona',response)
                if(response.code === 200){
                    this.setState({
                        personal:{
                            nombre: response.data.nombre,
                            apellido: response.data.apellido,
                            fechaNac:response.data.fechaNac,
                            cuil:response.data.cuil,
                            telefono:response.data.telefono,
                            email: response.data.email,
                            genero: response.data.genero
                        }
                    })
                }
            })
        }

        if(this.props.sesion.curriculumID){
            this.setState({loaderAPI: true})
            /**Cargo los datos de una persona */
            
            fetch(URL+"curriculums/ID/"+this.props.sesion.curriculumID, config)
            .then(res => res.json())
            .then(response =>{
                console.log("Primer get",response)
                if(response.code < 400){
                    this.setState({
                        personal:{
                            provinciaID:    response.data.provincia.ID,
                            localidadID:    response.data.localidad.ID,
                            nombre:         response.data.nombre,
                            apellido:       response.data.apellido,
                            direccion:      response.data.direccion,
                            cp:             response.data.cp,
                            email:          response.data.email,
                            cuil:           response.data.cuil,
                            puesto_postula: response.data.puesto_postula,
                            telefono:       response.data.telefono,
                            genero:         response.data.genero,
                            fechaNac:       response.data.fechaNac,
                            web:            response.data.web,
                            perfil:         (response.data.perfil) ? response.data.perfil : ""
                        }
                    })
                    this.setState({image: HOST+"/"+response.data.img})
                    this.setState({loaderAPI: false})
                    /**Cargo los datos de las provincias */
                    this.setState({provincias:[{
                        "ID": null,
                        "nombre": "Cargando..."}]})
                    this.setState({localidades:[{
                        "ID": null,
                        "nombre": "Cargando..."}]})

                    fetch(URL+"extras/provincias", config)
                    .then(res => res.json())
                    .then(response =>{
                        this.setState({provincias: response.data})
                        /**Cargo los datos de las localidades*/
                        fetch(URL+"extras/localidades/provinciaID/"+this.state.personal.provinciaID, config)
                        .then(res => res.json())
                        .then(response =>{
                            console.log("Localidades: ",response )
                            this.setState({localidades: response.data})

                        })
                        .catch(error => console.error(error))
                    })
                    .catch(error => console.error(error))
                }else{
                    /**Cargo los datos de las provincias */
                    this.setState({provincias:[{
                        "ID": null,
                        "nombre": "Cargando..."}]})
                    this.setState({localidades:[{
                        "ID": null,
                        "nombre": "Cargando..."}]})

                    fetch(URL+"extras/provincias", config)
                    .then(res => res.json())
                    .then(response =>{
                        this.setState({provincias: response.data})
                        /**Cargo los datos de las localidades*/
                        fetch(URL+"extras/localidades/provinciaID/1", config)
                        .then(res => res.json())
                        .then(response =>{
                            console.log("Localidades: ",response )
                            this.setState({localidades: response.data})

                        })
                        .catch(error => console.error(error))
                    })
                    .catch(error => console.error(error))
                }
                
            })
            .catch(error => console.error(error))
        }else{
            /**Cargo los datos de las provincias */
            this.setState({provincias:[{
                "ID": null,
                "nombre": "Cargando..."}]})
            this.setState({localidades:[{
                "ID": null,
                "nombre": "Cargando..."}]})

            fetch(URL+"extras/provincias", config)
            .then(res => res.json())
            .then(response =>{
                this.setState({provincias: response.data})
                /**Cargo los datos de las localidades*/
                fetch(URL+"extras/localidades/provinciaID/1", config)
                .then(res => res.json())
                .then(response =>{
                    console.log("Localidades: ",response )
                    this.setState({localidades: response.data})

                })
                .catch(error => console.error(error))
            })
            .catch(error => console.error(error))
        }
        
        
       
    }
    isEmpty = (e)=>{
        if(!e.value){
            e.className = "input is-danger"
            this.setState({control: false})
        }else{
            e.className = "input is-success"
        }
        
    }
    isCuil = (e)=>{
        
        let regex = /^([0-9]{2}-[0-9]{8}-[0-9]{1})$/;
        
        if(regex.test(e.value)){
            e.className = "input is-success"
        }else{
            e.className = "input is-danger"
            this.setState({control: false})
        }
    }
    isSuccess = (e)=>{
        e.className = "input is-success"
    }
    isEmail= (e) =>{
        let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(regex.test(e.value)){
            e.className = "input is-success"
        }else{
            e.className = "input is-danger"
            this.setState({control: false})
        }
        
    }
    isCkEditor=(data)=>{
        if(data){
            document.querySelector('.ck .ck-editor__main').classList.remove('is-danger')
            document.querySelector('.ck .ck-editor__main').classList.add('is-success')
        }else{
            document.querySelector('.ck .ck-editor__main').classList.add('is-danger')
            document.querySelector('.ck .ck-editor__main').classList.remove('is-success')
            this.setState({control: false})
        }
    }
    isTel= (e) =>{
        let regex = /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/;
        if(regex.test(e.value)){
            e.className = "input is-success"
        }else{
            e.className = "input is-danger"
            this.setState({control: false})
        }
        
    }
    showModal = () => {
        this.setState({show: true})
    }

    hideModal = () => {
        this.setState({show: false})
    };
    
    render(){
        return(
            <>
            {/**Loader de la API */}
            {(this.state.loaderAPI)?
            <div className="preloder">
                <div className="loader"></div>
            </div>
            :null}
            
            <div className="section section-2">
                <div className="container">    
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                Información personal
                            </p>
                        </header>
                        <div className="card-content columns is-multiline">
                            <div className=" column  is-full">
                                <label className="label">Foto de presentación</label>
                                <img className="user-image" src={this.state.image}/>
                               

                                

                                <div className='button-image'>
                                   
                                    <FileBase64
                                        type="file"
                                        multiple={ false }
                                        onDone={ this.getFiles.bind(this) } 
                                        accept="image/*"
                                        />
                                </div>
                                     
                                
                            </div>
                            <div className="field column  is-full">
                                <label className="label">Nombre <span>*</span></label>
                                <div className="control">
                                    <input disabled className="input" id="nombre" type="text" placeholder="Nombre" name="nombre" onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.nombre}/>
                                </div>
                            </div>
                            <div className="field column  is-full">
                                <label className="label">Apellido<span>*</span></label>
                                <div className="control">
                                    <input disabled className="input" id="apellido" type="text" placeholder="Apellido" name="apellido" onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.apellido}/>
                                </div>
                            </div>
                            <div className="field column  is-full">
                                <label className="label">Cuil<span>*</span></label>
                                <div className="control">
                                  {/**  <input className="input" id="cuil" type="text" placeholder="20-38926306-3"  data-mask="__-________-_" name="cuil" onInput={(e)=>this.isCuil(e.target)} onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.cuil} />
                                    **/}


                                    <Cleave placeholder="Ingresar Cuil"
                                    className="input" disabled={this.props.sesion.cuil} id="cuil" type="text" placeholder="20-38926306-3" name="cuil" onInput={(e)=>this.isCuil(e.target)} onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.cuil}
                                        options={{
                                            numericOnly: true,
                                            delimiters: ['-', '-'],
                                            blocks: [2, 8, 1],
                                        }
                                        }/>
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">Linkedin</label>
                                <div className="control">
                                    <input className="input" id="web" type="web" placeholder="ejemplo.com" name="web" onInput={(e)=>this.isSuccess(e.target)} onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.web ? this.state.personal.web : ""  }/>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Sexo <span>*</span></label>
                                <div className="select" >
                                    <select id="genero" name="genero" onChange={(e)=>this.handleInputChange(e)} onInput={(e)=>this.isEmpty(e.target)}>
                                        <option value="M">Hombre</option>
                                        <option value="F">Mujer</option>
                                        <option value="I">Otro</option>
                                    </select>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Fecha de nacimiento <span>*</span></label>
                                <div className="control">
                                    <input id="fechaNac" type="date" name="fechaNac" onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.fechaNac}/>
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">E-mail <span>*</span></label>
                                <div className="control">
                                    <input id="email" className="input" type="email" placeholder="ejemplo@gmail.com" name="email" onChange={(e)=>this.handleInputChange(e)} onInput={(e)=>this.isEmail(e.target)} value={this.state.personal.email}/>
                                </div>
                                
                            </div>
                            <div className="field column is-full">
                                <label className="label">Número de teléfono<span>*</span></label>
                                <div className="control">
                                    <input id="telefono" className="input" type="tel" placeholder="0800222444" name="telefono" onInput={(e)=>this.isTel(e.target)} onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.telefono}/>
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label"> Perfil<span>*</span></label>
                                    <CKEditor
                                        
                                        data={this.state.personal.perfil}
                                        editor={ClassicEditor}
                                        onChange={(e, editor) => {
                                            this.handleInputChangeCKEditor(editor.getData())
                                            this.isCkEditor(editor.getData())
                                           
                                        }}
                                        config={{
                                            placeholder: "mi experiencia esta orientada a...",
                                            toolbar: {
                                                items: [
                                                    'bold',
                                                    'italic',
                                                    'undo', 
                                                    'redo'
                                                ]
                                            },
                                        }}  />
                            
                            </div>
                        </div>
                    </div>
                    <div className="card  mt-5">
                        <header className="card-header">
                            <p className="card-header-title">
                                Información de residencia
                            </p>
                        </header>
                        <div className="card-content columns is-multiline">
                            <div className="field column is-full">
                                <label className="label">Dirección<span>*</span></label>
                                <div className="control">
                                    <input id="direccion" className="input" type="text" placeholder="Calle falsa 123"  onInput={(e)=>this.isEmpty(e.target)} name="direccion" onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.direccion}/>
                                </div>
                            </div>
                            <div className="field column is-full">
                                <label className="label">Codigo postal<span>*</span></label>
                                <div className="control">
                                    <input id="cp" className="input" type="text" placeholder="5700" name="cp"  onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.cp}/>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Provincia<span>*</span></label>
                                <div className="select">
                                    <select id="provinciaID" name="provinciaID"  onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.provinciaID}>
                                        {this.state.provincias.map(element=>(<option value={element.ID}>{element.nombre}</option>))}
                                    </select>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Localidad<span>*</span></label>
                                <div className="select">
                                    <select id="localidadID" name="localidadID"  onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.localidadID}>
                                        {this.state.localidades.map(element=>(<option value={element.ID}>{element.nombre}</option>))}
                                    </select>
                                </div>
                            </div>
                            
                           </div>
                        </div>
                        <div className="card  mt-5">
                        <header className="card-header">
                            <p className="card-header-title">
                                Puesto al que postula
                            </p>
                        </header>
                        <div className="card-content columns is-multiline">
                            <div className="field column is-full">
                                <label className="label">Nombre del puesto</label>
                                <div className="control">
                                    <input id="puesto_postula" className="input" type="text" placeholder="Operario" name="puesto_postula" onChange={(e)=>this.handleInputChange(e)} value={this.state.personal.puesto_postula ? this.state.personal.puesto_postula :"" }/>
                                </div>
                            </div>
                            
                            
                          </div>
                        </div>
                    <div className="columns  is-variable is-multiline is-centered mt-5">
                        { (this.state.loader) ?
                            <>
                            <CircularProgress color="secondary" />
                            
                            </>
                            :  <button className="button   my-5 is-rounded"  onClick={(e)=>this.handleSubmit(e)} >Siguiente paso </button>
                        }
                        
                        {this.state.success ?  <Redirect to='/experiencias' refresh="true" /> : null}
                    </div>
                    
                 </div>
                 <>
               
               <Modal show={this.state.show} handleClose={()=>this.hideModal()}>
               <article className="message is-danger">
                   <div className="message-body">
                       No se puede avanzar hasta completar <strong>todos</strong> los campos del formulario.
                   </div>
               </article>
               </Modal>
               
               </>
            </div>
            </>
        )
    }
    
}
const mapStateToProps = state => ({
    sesion: state.Sesion
  })

const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_SESION",
          data
        })
      },
    
  })
export default connect(mapStateToProps,mapDispatchToProps)(PersonalForm); 