import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux"
import Modal from '../../Components/Modal'
import {URL} from '../../Constants/API'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Switch } from '@mui/material'
class Cuenta extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            personaID: this.props.sesion.personaID,
            registro: { snSuscripto:false,
                        provinciaID:'',
                        localidadID:'',
                        nombre:'',
                        telefono:'',
                        genero:''
                    },
            provincias: [],
            localidades: [],
            loader:false, 
            show: false

        };
    } 
    showModal = () => {
        this.setState({show: true})
    }
    hideModal = () => {
        this.setState({show: false})
    }
    handleInputChange =(event) =>{
        if(event.target.name == 'snSuscripto'){
            this.setState({registro:{...this.state.registro,
            snSuscripto: event.target.value != 'true'}})

        }else{
            this.setState({ registro : {...this.state.registro,
                [event.target.name] : event.target.value}
            })
        }
        if(event.target.name==="provinciaID"){
            this.setState({localidades:[
                {
                    "ID": 1,
                    "nombre": "Cargando..."
                }
            ]})
            let config = { 
                method: "GET", 
                headers:{
                    'Content-Type': 'application/json'}
            }
            fetch(URL+"extras/localidades/provinciaID/"+event.target.value, config)
            .then(res => res.json())
            .then(response =>{
                
                this.setState({localidades: response.data})

            })
            .catch(error => console.error(error))
        }
    }
    
    async handleSubmit(){
        this.setState({loader:true})
        console.log('actualizando usuario',this.state.registro)
        let config = { 
            method: "PUT",
            body: JSON.stringify(this.state.registro), 
            headers:{
                'Content-Type': 'application/json'}
        }
        fetch(URL+"personas/id/"+this.state.personaID, config)
        .then(res => res.json())
        .then(response =>{})
        .catch(error => console.error(error))

        this.setState({loader:false})
        this.showModal()
    }
    componentDidMount() {
        /**Cargo los datos de una persona */
        let config = { 
            method: "GET", 
            headers:{
                'Content-Type': 'application/json'}
        }
        fetch(URL+"personas/id/"+this.state.personaID, config)
        .then(res => res.json())
        .then(response =>{
            console.log('get de persona', response)
            this.setState({
                registro:{
                    provinciaID: response.data.provincia.ID,
                    localidadID: response.data.localidad.ID,
                    nombre:response.data.nombre,
                    telefono:response.data.telefono,
                    genero:response.data.genero,
                    snSuscripto:response.data.snSuscripto
                }
            })
            /**Cargo los datos de las provincias */
            this.setState({provincias:[{
                "ID": null,
                "nombre": "Cargando..."}]})
            this.setState({localidades:[{
                "ID": null,
                "nombre": "Cargando..."}]})

            fetch(URL+"extras/provincias", config)
            .then(res => res.json())
            .then(response =>{
                console.log('get provincias',response)
                this.setState({provincias: response.data})
                /**Cargo los datos de las localidades*/
                fetch(URL+"extras/localidades/provinciaID/"+this.state.registro.provinciaID, config)
                .then(res => res.json())
                .then(response =>{
                    console.log('get localidades',response)
                    this.setState({localidades: response.data})

                })
                .catch(error => console.error(error))
            })
            .catch(error => console.error(error))
        })
        .catch(error => console.error(error))
        

        
    }

    render(){
        return(
       <>
       {/*
        (this.props.pago.checkoutID)?
        <article className="message is-danger w-100">
              <div className="message-body d-flex align-center">
                    Su membrecia ha vencido, pague para renovarla por 30 dias mas.
                  <Link to="/pagos" className="button is-dark is-outlined ml-auto"> Pagar</Link>
              </div>
          </article>
          :null
        */}
       <div className="container">
                <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                Información de la cuenta
                            </p>
                        </header>
                        <div className="card-content columns is-multiline">
                           
                            <div className="field column is-full">
                                <label className="label">Nombre y apellido</label>
                                <div className="control">
                                    <input className= "input" type="text" id="nombre" placeholder="Nombre" name="nombre" value={this.state.registro.nombre} onChange={(e)=>this.handleInputChange(e)} />
                                </div>
                            </div>
                            
                            <div className="field column is-full">
                                <label className="label">Género</label>
                                <div className="select">
                                    <select name="genero" id="genero" value={this.state.registro.genero} onChange={(e)=>this.handleInputChange(e)}>
                                        <option value="M">Hombre</option>
                                        <option value="F">Mujer</option>
                                        <option value="I">Otro</option>
                                    </select>
                                </div>
                            </div>
                        
                            <div className="field column is-full">
                                <label className="label">Teléfono</label>
                                <div className="control">
                                    <input className= "input" type="tel" placeholder="Teléfono" id="telefono" name="telefono"  value={this.state.registro.telefono} onChange={(e)=>this.handleInputChange(e)}/>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Provincia</label>
                                <div className="select">
                                    <select id="provinciaID" name="provinciaID" value={this.state.registro.provinciaID} onChange={(e)=>this.handleInputChange(e)}>
                                        {this.state.provincias.map(element=>(<option key={element.ID} value={element.ID}>{element.nombre}</option>))}
                                    </select>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Localidad</label>
                                <div className="select">
                                    <select id="localidadID" name="localidadID" value={this.state.registro.localidadID} onChange={(e)=>this.handleInputChange(e)} >
                                        {this.state.localidades.map(element=>(<option key={element.ID} value={element.ID}>{element.nombre}</option>))}
                                    </select>
                                </div>
                            </div>
                            <div className="field column is-half">
                                <label className="label">Avisos laborales</label>
                                <div className="control">
                                    <Switch name="snSuscripto" checked={this.state.registro.snSuscripto} value={this.state.registro.snSuscripto} onChange={(e)=>this.handleInputChange(e)}></Switch>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div className="columns  is-variable is-multiline is-centered">
                    { (this.state.loader) ?
                        <CircularProgress color="secondary" />
                        :  <button className="button  my-5 is-rounded" onClick={()=> this.handleSubmit()} >Guardar cambios </button>
                    }
                        
                    </div>
                    
                </div>
                <>
               
               <Modal show={this.state.show} handleClose={()=>this.hideModal()}>
               <article className="message is-success">
                   <div className="message-body">
                       Cambios guardados con éxito.
                   </div>
               </article>
               </Modal>
               
               </>
       </>
        )
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion,
    pago: state.Pago
  })
  
  const mapDispatchToProps = dispatch => ({
    
  })
export default connect(mapStateToProps,mapDispatchToProps) (Cuenta)  