import React from 'react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux";
import DestrezasSubCard from "./DestrezasSubCard"
import {URL} from '../../../../Constants/API'
class DestrezasCard  extends React.Component{
    constructor(props){
      super(props)
      
    }
    handleCreate(e){
      e.preventDefault()
        let destrezas = this.props.destrezas
        destrezas.push({
            curriculumID:   this.props.sesion.curriculumID,
            nombre:         "",
            nivel:          "1",
            order:           1
        })
        this.props.update(destrezas)
        this.forceUpdate()
    }
    componentDidMount(){
      /**Cargo los datos de los estudios */
     let config = { 
       method: "GET", 
       headers:{
           'Content-Type': 'application/json'}
     }
     fetch(URL+"curriculums/habilidades/curriculumID/"+this.props.sesion.curriculumID, config)
     .then(res => res.json())
     .then(response =>{
       //En caso de que no haya datos, pongo un valor "vacío"
       let destrezas = [
        {curriculumID:   this.props.sesion.curriculumID,
          nombre:         "",
          nivel:          "1",
          order:           1}]
      //---------------------------------------------------//
       let rows = (response.code <400) ? response.data.rows : destrezas
       this.props.update(rows)  
     })
     .catch(error => console.error(error))
    }
    render(){
        return(
            
            <div>
                {this.props.destrezas.map( (data,index,) =>(
                     <DestrezasSubCard key={"DestrezasCard-"+index} index={index} data={data}></DestrezasSubCard>
                ))}
                  
                <div className="columns has-text-centered">
                  <div className="column is-full">
                    <FontAwesomeIcon className="mr-3 cursor-pointer color-success" size="2x" icon={faPlusCircle} onClick={(e)=>this.handleCreate(e)} />
                  </div>
                </div>
                
             </div>
        );
        
    }
}
const mapStateToProps = state => ({
  sesion: state.Sesion,
  destrezas: state.Destrezas
})

const mapDispatchToProps = dispatch => ({
  update(data) {
    dispatch({
      type: "UPDATE_DESTREZAS",
      data
    })
  },
})
export default connect(mapStateToProps,mapDispatchToProps)(DestrezasCard)