import React from 'react'
import cv from '../../Assets/modelo_inicio-2-comp.png';
import cv_2 from '../../Assets/cv_2.png';
import crearCV from '../../Assets/crearCV.png'
import micvHome from '../../Assets/micvHome.png'  
import { Link } from 'react-router-dom';
import {Grid, Card, CardContent} from '@material-ui/core'
import Footer from './Footer'
import { connect } from "react-redux";
import ReactGA from 'react-ga';
import '../../Sass/home.scss'

//import Promo from './Promo'
class Home extends React.Component{
    constructor (props){
        super(props)
    }
    componentDidMount(){
        ReactGA.initialize('UA-142549046-23');
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(window.location.pathname + window.location.search)
    }
    update(){
        this.props.update({...this.props.sesion, curriculumID: null})
    }
    render(){
        return(
            <>
               {/* <Promo /> */}
                <section className="hero is-medium">
                    
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h1>
                                <img src={micvHome}/>
                            </h1>
                            <label>
                                <div className='button-container'>
                                    <div className='text-button'>
                                        <label>¿Necesitas un nuevo cv?</label>
                                        {(this.props.sesion.personaID)?
                                            <Link className="button button-image shadow my-5 is-rounded" onClick={()=>{this.update()}}  to="informacion_personal" >Crear curriculum</Link>
                                            :
                                            <Link className="button button-image shadow my-5 is-rounded" to="/login">Crear curriculum</Link>

                                        }
                                    </div>
                                    <div className='text-button'>
                                        <label>¿Buscas trabajo?</label>
                                        <Link className='button button-image shadow my-5 is-rounded' to="avisos">Ver avisos laborales</Link>
                                    </div>
                                </div>  
                            </label>
                            <p>
                                <Link className="button button-image shadow hero-button my-5 is-rounded" onClick={()=>{this.update()}}  to="informacion_personal" > <img src={crearCV} /> </Link>
                            </p>
                            
                        </div>
                    </div>
                    </section>
                    
                    <section className="section margin-y">
                        <div className="container">

                        <Grid container  spacing={1}>
                            <Grid item className="order-2" xs={12} md={3}>
                                <div className="mt-5">
                                    <label className="label label-2">Comienza a completar tu nuevo curriculum</label>
                                    <p>Sigue los pasos y completa:</p>
                                    <p>-Tus estudios</p>
                                    <p>-Tus experiencias laborales + referencias</p> 
                                    <p>-Tu información de contacto</p>
                                    
                                </div>

                                <div className="mt-5">
                                    <label className="label label-2">Aquí amplía información sobre ti :</label>
                                    <p>-Idiomas</p> 
                                    <p>-Habilidades</p>
                                    <p>-Hobbies</p> 
                                    <p>-Logros</p> 
                                    
                                </div>
                            
                                
                            </Grid>
                            <Grid className="has-text-centered order-1" item xs={12} md={6} >
                                <img className="shadow cv-image " src={cv} />
                            </Grid>
                            <Grid item className="order-3" xs={12} md={3}>
                                <div className="mt-5">
                                    <label className=" label label-2">Escoge un diseño efectivo</label>
                                    <p>-Hemos seleccionado 5 plantillas de curriculum que logran un buen impacto.</p>
                                    <p>-Nuestro equipo trabaja diariamente lograr mejores modelos.</p>
                                    <p>-En todos los casos tu cv contara con un formato claro, resumido y directo.</p>
                                    
                                </div>

                                <div className="mt-5">
                                    <label className="label label-2">Rápido y Fácil</label>
                                    <p>Crearas un CV en minutos y lo enviaras por email de forma inmediata.</p>
                                    
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className="has-text-centered mt-5" alignItems="center">
                            <Grid item md={12}  >
                                <h2>micurriculum.com.ar AUMENTA TUS POSIBILIDADES DE CONSEGUIR UNA ENTREVISTA. </h2> <br/>
                                <h2>LOGRA UN CV DIRECTO.</h2> <br/>
                                <h2>RESALTA TUS CAPACIDADES.</h2>
                                <Link className="button button-image   my-5 is-rounded" onClick={()=>{this.update()}}  to="informacion_personal" > <img src={crearCV} /> </Link>
                            </Grid>
                        
                        </Grid>
                
                        </div>

                    </section>
                    
                    <section className="section margin-y">
                        <div className="container">
                    <Grid container className="has-text-centered my-5" alignItems="center">
                        <Grid item md={12} >                            
                            <h2>Los mejores modelos profesionales de CV disponibles</h2>
                        </Grid>                    
                    </Grid>
                        
                    <Grid container  spacing={1}>
                   
                    <Grid className="has-text-centered" item xs={12} md={12} >
                        <img className="shadow " src={cv_2} />
                    </Grid>
                    
                </Grid>
                <Grid container className="has-text-centered mt-5" justify="center" alignItems="center" >
                    <Grid item md={12} >
                        
                       
                    <Link className="button button-image   my-5 is-rounded" onClick={()=>{this.update()}}  to="informacion_personal" > <img src={crearCV} /> </Link>

                    </Grid>
                
                
                </Grid>
                
                        </div>

                    </section>
                    <>
               

                    

                    <div className="section">
                        <div className="container">
                        <Grid container className="has-text-centered my-5"  justify="center" spacing={6}>
                            <Grid item md={12} className=" mb-5" >                            
                                <h2 className="bold">¿Por qué micurriculum.com.ar?</h2>
                            </Grid> 
                            <Grid item md={6} >
                                                      
                                
                                <p>-La presentación de un CV estructurado, es más efectivo al momento de postularse</p>
                                <p>-Es limitado tiempo el que disponen hoy los profesionales de RRHH para analizarlos en la primera etapa</p>
                                <p>-Son frecuentes los errores del armado; te ayudaremos a evitarlos</p>
                            
                            </Grid>       
                                              
                        </Grid>
                        </div>
                    </div>
                    <div className="section">
                        <div className="container">
                        <Grid container className=" my-5"  justify="center" spacing={6}>
                            <Grid item md={12} className="has-text-centered mb-5" >                            
                                <h2 className="bold">Ademas micurriculum.com.ar te permitirá </h2>
                            </Grid> 
                            <Grid item md={6} >
                                                      
                                <p>Cargar tus datos para que empresas puedan ver tu experiencia y capacidad  </p>
                                
                            
                            </Grid>       
                                              
                        </Grid>
                        </div>
                    </div>
                    <div className="section">
                        <div className="container">
                        <Grid container className=" my-5" justify="center" spacing={6}>
                            <Grid item md={12} className="has-text-centered mb-5" >                            
                                <h2 className="bold">Que te ofrecemos:</h2>
                            </Grid> 
                            <Grid item md={6} >
                                                      
                                <p>-Consejos para lograr tu objetivo, en cada paso</p>
                                <p>-Acceder a tu información laboral y adaptar el cv de acuerdo a la postulación</p>
                                <p>-Almacenar los Cv en tu cuenta personal para poder, editarlo, enviarlo y guardarlo en un PDF en formato identificable</p>
                                <p>-Por medio de la plataforma, podrás enviarlo vía email en forma directa a quien desees.</p>
                                <p>-Podrás disponer de tus postulaciones en un solo lugar, cuando necesites y donde estés.</p>
                                
                            
                            </Grid>       
                                              
                        </Grid>
                        </div>
                    </div>
            </>
                    <Footer></Footer>
            </>
            
        )
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_SESION",
          data
        })
      },
    
  })
export default  connect(mapStateToProps,mapDispatchToProps) (Home)