import React from 'react'
import { connect } from "react-redux"
import CurriculumCard from './CurriculumCard';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link, Redirect} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import {URL} from '../../Constants/API'
class Curriculums extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            cv: [],
            redirect: false,
            loader: false,
            loader_cvs: true
        };
    }
    componentDidMount() {
        let config = { 
            method: "GET", 
            headers:{
                'Content-Type': 'application/json'}
        }
        fetch(URL+"curriculums/personaID/"+this.props.sesion.personaID, config)
        .then(res => res.json())
        .then(response =>{
            console.log("Curriculums: ", response )
            if(response.code>=400){
                this.setState({cv: []})
            }else{
                this.setState({cv: response.data})
            }
           
            this.setState({loader_cvs: false})
        })
        .catch(error => console.error(error))

    }
    redirect(){
        this.props.update({...this.props.sesion, curriculumID: null})
        this.setState({loader: true})
        this.setState({redirect: true})
    }
    render(){
        return(
       <>
       {/*
        (this.props.pago.checkoutID)?
        <article className="message is-danger w-100">
              <div className="message-body d-flex align-center">
                    Su membrecia ha vencido, pague para renovarla por 30 dias mas.
                  <Link to="/pagos" className="button is-dark is-outlined ml-auto"> Pagar</Link>
              </div>
          </article>
          :null
        */}
        {(this.state.redirect ?  <Redirect to='/informacion_personal' /> : null)}
        { (this.state.loader) ?
            <CircularProgress color="secondary" />
            : <button className="button is-dark is-outlined" onClick={()=>this.redirect()}> <FontAwesomeIcon className="mr-3 color-success" icon={faPlusCircle} /> Agregar</button>
        }
        { (this.state.loader_cvs) ?
            <div className="mt-5 pt-5"><CircularProgress  color="secondary" /></div>
            : null
        }
        
       {
           this.state.cv.map(element=>(<CurriculumCard key={element.ID} curriculumID={element.ID} title={element.nombre+" "+element.apellido+" - "+element.createdAt.slice(0,10).replace(/-/g,"/")} />))
       }
      
        
       </>
        )
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion,
    pago: state.Pago
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_SESION",
          data
        })
      },
    
  })
export default connect(mapStateToProps,mapDispatchToProps) (Curriculums) 