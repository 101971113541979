import React from 'react'
function Section(props){

    return (
        <>
        <div className="section section-2">
            {props.children}
        </div>
        </>
    )

}
export default Section