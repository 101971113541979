import React from 'react'
import { faPenSquare } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Redirect, withRouter, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from "react-redux"
import Modal from '../../Components/Modal'
import Mail from '../../Pages/CrearCV/4.Mail/Mail'
import '../../Sass/curriculumCard.scss'
class CurriculumCard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loader: false,
            show: false,
            showPdf: false
          };
    }
    vistaPrevia(){
        
        /**if(this.props.pago.checkoutID){
            this.setState({show:true})
        }else{**/
            this.props.update({...this.props.sesion, curriculumID: this.props.curriculumID})
            this.props.history.push("/preview")
        //}
        
        
    }
    enviarEmail(){
        if(this.props.pago.checkoutID){
            this.setState({show:true})
        }else{
            this.props.update({...this.props.sesion, curriculumID: this.props.curriculumID})
            this.setState({showPdf: true})
        }
    }
     memorizacionPrevia(){
        this.setState({loader:true})
        this.props.update({...this.props.sesion, curriculumID: this.props.curriculumID})
        this.setState({loader:false})
        this.setState({redirect: true})
    }
    
    render(){
        return(
       <>
       {this.state.redirect ?  <Redirect to='/informacion_personal' /> : null}

       <div className="card card-dashboard  border my-3 ">
            <div className="card-header px-5 py-3 level ">

                <div className="level-left">
                    <div className="level-item">
                        <p>{this.props.title}</p>
                    </div>
                </div>
                <div className="level-right"> 
                    <div className="level-item">
                    { (this.state.loader) ?
                        <CircularProgress color="secondary" />
                        :   <FontAwesomeIcon className="is-medium edit color-success" icon={faPenSquare} onClick={()=>this.memorizacionPrevia()} />
                    }
                        
                    </div>
                    
                    <div className="level-item">
                         <FontAwesomeIcon className="is-medium edit " onClick={()=>this.vistaPrevia()} icon={faEye}  />
                    </div>
                    <div className="level-item">
                         <FontAwesomeIcon className="is-medium edit " onClick={()=>this.enviarEmail()} icon={faEnvelope}  />
                    </div>
                </div>
                
                
            
            </div>
            

       </div>
       <Modal show={this.state.show} handleClose={()=>this.setState({show: false})}>
            <article className="message is-danger">
                <div className="message-body d-flex align-center">
                    Para continuar con el proceso es necesario acceder a la membresía por 30 días.
                    <Link to="/pagos" className="button is-dark is-outlined ml-auto"> Pagar</Link>
                </div>
            </article>
        </Modal>
        {/**Modal de envío de pdf */}
        <Modal show={this.state.showPdf} handleClose={()=>this.setState({showPdf: false})}>
                <Mail/>
        </Modal>
       </>
        )
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion,
    pago: state.Pago
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_SESION",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps) (withRouter(CurriculumCard)) 