import React from 'react'
const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal is-active" : "modal is-clipped";
  
    return (
      <div className={showHideClassName}>
      <div className="modal-background" onClick={handleClose}></div>
      <div className="modal-content">
            {children}
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={handleClose}></button>
    </div>
      
    );
  };
export default Modal