import React from 'react';
import ReactDOM from 'react-dom';
import 'bulma/bulma.sass';
import './Sass/style.scss'
import Router from  './Router/Router'
import favicon from './Assets/favicon.ico'
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux"
import Store from './Store/Store'
import {Helmet} from "react-helmet";

ReactDOM.render(
  <React.StrictMode>
   
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mi curriculum</title>
        <link rel="shortcut icon" type="image/png" href={favicon} />
             
      </Helmet>
      
        <Provider store={Store}>       
              <Router/>
        </Provider>
       
      
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
