import React from 'react'
import CardTemplate from './CardTemplate'
import { Link, withRouter  } from 'react-router-dom';
import { connect } from "react-redux";
import Modal from '../../../Components/Modal'
import FileBase64 from '../../../Components/FileBase64'
import Login from '../../Landing/Login'
import {URL, HOST} from '../../../Constants/API'
class Templates extends React.Component {
    constructor(props){ 
        super(props);
        this.state = {
            pdf_con_foto: [],
            pdf_sin_foto: [],
            show: false,
            showPago: false,
            showFoto: false,
            files: [],
            image: null,
            disable: false,
        }
    }
    getFiles(files){
        this.setState({ files: files })
        this.setState({image:this.state.files.base64 })
        /**Guardo imagen */
        fetch(URL + "curriculums/imgs/id/"+this.props.sesion.curriculumID,{ method: "POST", body: JSON.stringify({"base64":this.state.image}), headers:{'Content-Type': 'application/json'} })
        .then(res =>res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
            if(response.code< 400){
                this.setState({disable: false})
            }
        })
    }
    async componentDidMount() {
        let config = { 
            method: "GET", 
            headers:{'Content-Type': 'application/json'}}
        fetch(URL+"templates", config)
        .then(res =>res.json())
        .then(response =>{
            this.setState({
                pdf_con_foto: response['con_foto']
            })
            this.setState({
                pdf_sin_foto: response['sin_foto']
            })
            this.getPersonal()
            
        })
        

    }
    getPersonal(){
        /**Cargo los datos de una persona */
        let config = { 
            method: "GET", 
            headers:{
                'Content-Type': 'application/json'}
        }
        fetch(URL+"curriculums/ID/"+this.props.sesion.curriculumID, config)
        .then(res => res.json())
        .then(response =>{
            if(response.code< 400 && response.data['img']){
                this.setState({image: HOST+"/"+response.data.img})
                this.props.update(this.state.pdf_con_foto[0])
            }else{
                this.props.update(this.state.pdf_sin_foto[0])
                this.setState({disable: true})
            }
        })
        .catch(error => console.error(error))
    } 
    To(){
        //Hago el PATCH de template por curriculumID
        let config = { 
            method: "PATCH",
            body: JSON.stringify({template: this.props.pdf.ID}), 
            headers:{'Content-Type': 'application/json'}}
        fetch(URL+"curriculums/template/id/"+this.props.sesion.curriculumID, config)
        .then(res =>res.json())
        .then(response =>{
            console.log(response)
            if(this.props.sesion.personaID){
                this.saveCV(this.props.sesion.personaID)
                /**if(this.props.pago.checkoutID){
                    this.setState({showPago: true})
                }else{**/
                    this.props.history.push("/preview")
                //}
            }else{
                this.setState({show: true})
            }
        })
        
    }
    saveCV(personaID){
        let body= { personaID: personaID}
        let config = { 
            method: 'PATCH', 
            body: JSON.stringify(body),
            headers:{'Content-Type': 'application/json'}}

        fetch(URL + "curriculums/persona/id/"+this.props.sesion.curriculumID, config)
        .then(res =>res.json())
        .catch(error => console.error('Error:', error))
        .then(response => console.log(response));

    } 
    render(){
        return(<>
            <section className="section section-2">
                <div className="container">
                    <div className="columns is-centered title-template-section ">
                        <h1 className="has-text-white">Con Foto</h1>
                    </div>
                    <div className="columns  is-variable is-multiline is-centered">
                        
                        {
                            this.state.pdf_con_foto?.map( (data,index) =>(                                
                                <CardTemplate disable={this.state.disable} key={index+Math.random()} data={data} ></CardTemplate>                               
                           ))
                        }
                    </div>
                    <div className="columns  is-variable is-multiline is-centered my-5 pb-5">  
                        <button className="button is-rounded" onClick={(e)=>{e.preventDefault(); this.setState({showFoto: true})}}> Agregar foto </button>  
                    </div>
                    <div className="columns is-centered title-template-section">
                        <h1 className="has-text-white">Sin Foto</h1>
                    </div>
                    <div className="columns  is-variable is-multiline is-centered">
                        {
                            this.state.pdf_sin_foto?.map( (data,index) =>(                                
                                <CardTemplate key={index+Math.random()} data={data} ></CardTemplate>                               
                           ))
                        }
                    </div>
                    <div className="columns  is-variable is-multiline is-centered mt-5">
                        <Link className="button  is-white is-rounded mr-3 is-outlined" to="/experiencias">Atrás </Link>  
                        <button className="button is-rounded" onClick={()=>this.To()}>Crear CV </button>  
                    </div>
                    
                </div>
            </section>
            {/**Debe iniciar sesion para continuar*/}
            {(!this.props.sesion.personaID)?
            <Modal show={this.state.show} handleClose={()=>this.setState({show: false})}>
                <article className="message is-danger">
                    <div className="message-body">
                        Debe iniciar sesión para continuar con el armado del curriculum.
                    </div>
                </article>
                <Login/>
            </Modal>
             /**Debe pagar su cuenta para seguir*/
            :<Modal show={this.state.showPago} handleClose={()=>this.setState({showPago: false})}>
                <article className="message is-danger">
                    <div className="message-body d-flex align-center">
                        Usted registra deuda
                        <Link to="/pagos" className="button is-dark is-outlined ml-auto"> Pagar</Link>
                    </div>
                </article>
            </Modal>
            }

            {/**Modal para subir foto*/
            <Modal show={this.state.showFoto} handleClose={()=>this.setState({showFoto: false})}>
                <div className="card">
                    <div className="card-content columns is-multiline has-text-centered">
                        <div className=" column  is-full">
                            <label className="label">Foto de presentación</label>
                            <img className="user-image" src={this.state.image}/>
                            <div className='button-image'>       
                                <FileBase64
                                    type="file"
                                    multiple={ false }
                                    onDone={ this.getFiles.bind(this) } 
                                    accept="image/*"
                                />
                            </div>            
                        </div>
                    </div>
                </div>
            </Modal>}

           
            
            
            </>)
    }
    
}
const mapStateToProps = state => ({
    pdf: state.Pdf,
    sesion: state.Sesion,
    pago: state.Pago,
  })

const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_PDF",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps)( withRouter (Templates))