import React from 'react'
import { faPenSquare } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Redirect, withRouter, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import {URL} from '../Constants/API';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';


class SeleccionArchivos extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            archivos:[]
        };
    }

    componentDidMount(){
        let config = { 
            method: "GET", 
            headers:{
                'Content-Type': 'application/json'}
        }
        fetch(URL+"files/personaID/"+this.props.sesion.personaID, config)
        .then(res => res.json())
        .then(response =>{
            console.log("Archivos: ", response )
            if(response.code>=400){
                this.setState({archivos: []})
            }else{
                this.setState({archivos: response.data})
            }
           
            this.setState({loader_cvs: false})
            console.log(this.state.archivos)

        })
        .catch(error => console.error(error))
    }

    vistaPrevia(){
        
    }
    enviarEmail(){
        if(this.props.pago.checkoutID){
            this.setState({show:true})
        }else{
            this.setState({showPdf: true})
        }
    }
     memorizacionPrevia(){
        this.setState({loader:true})
        this.props.update({...this.props.sesion, curriculumID: this.props.curriculumID})
        this.setState({loader:false})
        this.setState({redirect: true})
    }
    
    render(){
        return(
       <>
            <FormGroup>
                {(this.state.archivos.length>0)?
                    this.state.archivos.map(element=>(<FormControlLabel control={<Checkbox onChange={(event)=>this.props.handleChange(element.ID,event)}/>} label={element.archivo} />))
                    :
                    <Alert severity="info"
                        action={
                            <Button color="inherit" size="small" >
                            <Link to='/archivos'>Ir a archivos</Link>
                            </Button>
                        }
                    >
                        <AlertTitle>No tienes archivos</AlertTitle>
                        Carga los tuyos para adjuntarlos con tu cv
                    </Alert>
                    
                }
            </FormGroup>
       </>
        )
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion,
    pago: state.Pago
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_SESION",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps) (withRouter(SeleccionArchivos)) 