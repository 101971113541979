import React from 'react'
import {
    HashRouter,
    Switch,
    Route
  } from "react-router-dom";
import PersonalForm from '../Pages/CrearCV/1.Perfil/PersonalForm';
import Experience from '../Pages/CrearCV/2.Experiencia/Experience';
import Templates from '../Pages/CrearCV/3.Templates/Templates'
import Login from '../Pages/Landing/Login'
import Section from '../Pages/Landing/Section'
import NavBar from '../Pages/Landing/NavBar'
import Registro from '../Pages/Landing/Registro'
import Dashboard from '../Pages/Dashboard/Dashboard';
import Curriculums from '../Pages/Dashboard/Curriculums';
import Cuenta from '../Pages/Dashboard/Cuenta';
import Pagos from '../Pages/Dashboard/Pagos';
import Home from '../Pages/Landing/Home';
import Contacto from '../Pages/Landing/Contacto';
import { connect } from "react-redux"
import PrivateRoute from './PrivateRoute'
import Preview from '../Pages/CrearCV/3.Templates/Preview';
import Precios from '../Pages/Landing/Precios'
import Archivos from '../Pages/Dashboard/Archivos'
import ContactoPersonas from '../Pages/Landing/Contacto-personas';
import { Avisos } from '../Pages/Landing/Avisos/Avisos';
import { DetalleAviso } from '../Pages/Landing/Avisos/Detalle'
import { FormPostulacion } from '../Pages/Landing/Avisos/FormPostulacion';
import { Postulacion } from '../Pages/Landing/Avisos/Postulacion';
import RecuperarPassword from '../Pages/Landing/recuperar-password';


function Router({sesion}){
    return(
      <HashRouter >
        
        <Switch>
          <Route exact path="/informacion_personal">
            <NavBar/>
            <PersonalForm />
          </Route>

          <PrivateRoute path="/experiencias" redirect="/informacion_personal" auth={sesion.curriculumID}>
            <NavBar/>
            <Experience/>
          </PrivateRoute>

          <PrivateRoute path="/templates" redirect="/informacion_personal" auth={sesion.curriculumID}>
            <NavBar/>
            <Templates/>
          </PrivateRoute>

          <Route exact path="/login">
            <NavBar/>
            <Section>
              <Login />
            </Section>
          </Route>
          <Route exact path="/registro">
            <NavBar/>
           <Registro />
          </Route>
          <Route exact path="/recuperar-contraseña">
            <NavBar/>
            <Section>
              <RecuperarPassword />
            </Section>
          </Route>
          <Route exact path="/home">
            <NavBar/>
            <Home />
          </Route>

          <Route exact path="/postulacion/:avisoID/:titulo">
            <NavBar/>
            <Postulacion />
          </Route>

          <Route exact path="/avisos">
            <NavBar/>
            <Avisos/>
          </Route>
          <Route exact path="/avisos/:id">
            <NavBar/>
            <DetalleAviso/>
          </Route>
          
          <Route exact path="/precios">
            <NavBar/>
            <Precios />
          </Route>
          <Route exact path="/contacto">
            <NavBar/>
            <Contacto />
          </Route>
          <Route exact path="/contacto-personas">
            <NavBar/>
            <ContactoPersonas />
          </Route>
          <Route exact path="/preview">
            <NavBar/>
            <Preview /> 
          </Route>
          <Route exact path="/">
            <NavBar/>
            <Home />
          </Route>

          <PrivateRoute path="/cuenta" redirect="/login" auth={sesion.personaID}>
            <Dashboard title="Mi cuenta">
                <Cuenta/>
            </Dashboard>
          </PrivateRoute>

          <PrivateRoute path="/curriculums" redirect="/login" auth={sesion.personaID}>
            <Dashboard title="Mis Curriculums">
                <Curriculums/>
            </Dashboard>
          </PrivateRoute>

          <PrivateRoute path="/archivos" redirect="/login" auth={sesion.personaID}>
            <Dashboard title="Archivos">
                <Archivos/>
            </Dashboard>
          </PrivateRoute>
        
          <PrivateRoute path="/pagos" redirect="/login" auth={sesion.personaID}>
            <Dashboard title="Mi suscripción">
                <Pagos/>
            </Dashboard>
          </PrivateRoute>

          
          
          <Route path="*">
              <NavBar/>
              <h1>PAGINA NO ENCONTRADA </h1>
          </Route>
        </Switch>
      </HashRouter>
    )
}


const mapStateToProps = state => ({
  sesion: state.Sesion 
})

const mapDispatchToProps = dispatch => ({

})
export default connect(mapStateToProps,mapDispatchToProps)(Router)