const KingOfCookies = new Object()

KingOfCookies.set = function (name, json){ 
	let data = JSON.stringify(json)
	document.cookie = `${name}=${data}; `
}

KingOfCookies.get= function (name) {
	const value = `; ${document.cookie}`
	const parts = value.split(`; ${name}=`)
  	if (parts.length === 2) return JSON.parse(parts.pop().split(';').shift())
} 
 
/**KingOfCookies.deleteAll= function (){
	var c = document.cookie.split("; ")
	for (i in c) 
	  document.cookie =/^[^=]+/.exec(c[i])[0]+"=;expires=Thu, 01 Jan 1970 00:00:00 GMT"  
}**/
KingOfCookies.delete= function (name){
	document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
export default KingOfCookies