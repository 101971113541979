import React from 'react'
import { connect } from "react-redux";
import { faPenSquare, faListUl, faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'
import LaboralCard from './LaboralCard';
import {URL} from '../../../../Constants/API'
import Modal from '../../../../Components/Modal'

class LaboralSubCard extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          activeEmpresa: !Boolean(this.props.data.nombre) ,
          activeLaboral: false,
          control: false,
          loader: false,
          show: false,
          empresa: this.props.data
        };
    }
    
    componentWillReceiveProps(){
        this.setState((state, props) => ({
            empresa: props.data
          }));
          this.setState((state, props) => ({
            activeEmpresa: !Boolean(props.data.nombre)
        }));
    }
    handleClick(e){
        e.preventDefault();
        this.setState({
            activeEmpresa: !this.state.activeEmpresa
        })       
    }
    async handleSave(e,i){
        this.setState({loader:true})
        e.preventDefault()
        await this.formControl()
        if(this.state.control){
            if (this.state.empresa.ID) {
                /**Hago el PUT en caso de que el ID ya exista */
                let config ={
                    method: "PUT", 
                    body: JSON.stringify(this.state.empresa), 
                    headers:{'Content-Type': 'application/json'} }
                fetch(URL + "curriculums/empresas/id/"+this.state.empresa.ID, config )
                .then(res => res.json())
                .then(response => {
                    this.setState({activeEmpresa: false})
                    this.setState({activeLaboral: true})
                    this.setState({loader:false})
                })
                
            }else{
                /**Hago el post en caso de que el ID no exista */
                console.log("POST")
                let config ={
                    method: "POST", 
                    body: JSON.stringify(this.state.empresa), 
                    headers:{'Content-Type': 'application/json'} }
                fetch(URL + "curriculums/empresas", config )
                .then(res => res.json())
                .then(response => {
                    console.log(response)
                    if(response.code<400){
                        this.setState({
                            empresa: {...this.state.empresa, ID : response.ID }
                        })
                        this.setState({activeEmpresa: false})
                        this.setState({activeLaboral: true})
                        this.setState({loader:false})
                        console.log(this.state.empresa)
                    }else{
                        this.setState({loader:false})
                        this.setState({show:true})
                    }
                    
                    
                })
                
            }
            
            
        }else{
            this.setState({loader:false})
            this.setState({show:true})
        }
        
    }

    handleDelete(e){
        this.setState({loader:true})
        e.preventDefault()
        /**Elimino el empresa */
        let config ={ 
            method: "DELETE", 
            headers:{'Content-Type': 'application/json'}}
        /**Funcion para actualizar */
        const update = ()=>{
            let configGet = { 
                method: "GET", 
                headers:{
                    'Content-Type': 'application/json'}}
            fetch(URL+"curriculums/empresas/curriculumID/"+this.props.sesion.curriculumID, configGet)
            .then(res => res.json())
            .then(response =>{
                let rows = (response.code <400) ? response.data : []
                this.props.update(rows)
            })
            .catch(error =>{
                this.setState({loader:false})
                console.error(error)
            }) 

        }
        /**Si la empresa está guardado lo elimino del server  */
        if(this.state.empresa.ID){
            fetch(URL + "curriculums/empresas/id/"+this.state.empresa.ID, config )
            .then(res => res.json())
            .then(response => {
                this.setState({loader:false})
                update()  
            })
            .catch(Error => this.setState({loader:false}))
        }else{
            /**Si la empresa no está guardado, actualizo redux */
            update()
        }
        

        /**/  
    }
    
    handleInputChange (e, i) {
        this.setState({
            empresa: {...this.state.empresa,[e.target.name] : e.target.value}
        })
    }
    isEmpty(e){
        if(!e.value){
            e.className = "input is-danger"
            this.setState({control: false})
        }else{
            e.className = "input is-success"
        }
    }
    handleCloseLaboral(e){
        e.preventDefault();
        
        this.setState({activeLaboral: !this.state.activeLaboral})
        console.log(this.state.empresa)
    }
    handleClassName (){
        let clase = "border  px-5 py-5 mb-5 level experienceSubCard"
        let hidden = "is-hidden"
        let retorno
        if (!this.state.activeLaboral && !this.state.activeEmpresa){
            retorno = clase
        }else{
            retorno = hidden
        }
        return retorno
    }
    
    formControl(){
        this.setState({control: true})
        this.isEmpty(document.getElementById("LaboralSubCard__empresa--"+this.props.index))
        
    }
    
    render(){
        return(
            <>
            <div  className={this.handleClassName()} >
                <div className="level-left">
                    <div className="level-item">
                        <p> {this.state.empresa.nombre}  </p>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                         <FontAwesomeIcon className="is-medium edit " icon={faListUl} onClick={(e)=>{e.preventDefault(); this.setState({activeLaboral: !this.state.activeLaboral})}} />
                    </div>
                    <div className="level-item">
                         <FontAwesomeIcon className="is-medium edit color-success" icon={faPenSquare} onClick={(e)=>{this.handleClick(e)}} />
                    </div>
                    <div className="level-item">
                        <button className="delete has-text-warning is-medium" aria-label="delete" onClick={(e)=>this.handleDelete(e)} > </button>
                        
                    </div>
                    
                    
                    
                </div>
                
                
            </div>
            {/** Edicion de empresa*/}
            <div className={this.state.activeEmpresa ? "mb-5   border py-5 px-5": "is-hidden"} >
            <div className=" mb-5 columns is-multiline">
            <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                Información de la empresa
                            </p>
                        </header>
            <div className="card-content columns is-multiline">
                
               
                    <div className="field column is-full">
                        
                        <label className="label">Empresa / Razón social  <span>*</span></label> 
                        <div className="control">
                            <input className="input" id={"LaboralSubCard__empresa--"+this.props.index} value={this.state.empresa.nombre}  onChange={(e)=>{this.handleInputChange(e, this.props.index)}}  onInput={(e)=>this.isEmpty(e.target)}  type="text" name="nombre" placeholder="P.ej: Arcor SA" />
                        </div>
                        <div className="field column is-full">
                            <label className="label">Sugerencia</label>
                            <p className="pl-1">La información correcta de tu anterior empleador es fundamental.</p>
                        </div>
                    </div>
                </div>
                <footer className="card-footer py-5 px-5">
                    { (this.state.loader) ?
                        <CircularProgress color="secondary" />
                        :<><button className="button is-success mr-3" onClick={(e)=>this.handleSave(e, this.props.index)} > Guardar</button>
                        <button className="button is-danger" onClick={(e)=>this.handleDelete(e)} >Eliminar </button>
                        </>
                    }
                            
                        </footer>
                
                </div>
                
                
                </div>
               
            </div>
            {/** Historial en la empresa*/}
            <div className={this.state.activeLaboral ? "mb-5   border py-5 px-5": "is-hidden"} >
                <div className=" mb-5 columns is-multiline">
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                {this.state.empresa.nombre}
                                <FontAwesomeIcon className="is-medium edit ml-auto" icon={faArrowAltCircleUp} onClick={(e)=>{this.handleCloseLaboral(e)}} />
                            </p>
                            
                        </header>
                        <div className="card-content">
                            {(this.state.empresa.ID)?
                            <LaboralCard data={this.state.empresa} index={this.props.index} />
                            :null

                            }
                            
                            
                        </div>  
                    </div>    
                </div>  
            </div>
    
            <Modal show={this.state.show} handleClose={()=>this.setState({show: false})}>
                <article className="message is-danger">
                    <div className="message-body ">
                        Error - no se completó la información correctamente
                    </div>
                </article>
            </Modal>
            </>
        )
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion,
   
  })

const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_EMPRESAS",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps)(LaboralSubCard) 