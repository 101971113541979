import React from 'react'
import {Grid, ListItemIcon,ListItem,ListItemText} from '@material-ui/core';
import {URL} from '../../Constants/API'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { connect } from "react-redux"
class Pagos extends React.Component {
    constructor(props){
        super(props)
        
    }
    componentDidMount(){
        let config = { 
            method: 'GET',
            headers:{'Content-Type': 'application/json'}}
        fetch(URL + "personas/deuda/id/"+this.props.sesion.personaID+"/planPagoID/"+this.props.sesion.personaID, config)
        .then(res =>res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
            if(response){  
                this.props.update({...this.props.pago, checkoutID: response.checkoutID })

            }
            
        })
    }
    render(){
        return(
            <>
            {/*
                (this.props.pago.checkoutID)?
                <article className="message is-danger w-100">
                    <div className="message-body d-flex align-center">
                    Su membrecia ha vencido, pague para renovarla por 30 dias mas.
                    </div>
                </article>
                :null
               */ }
            <div className="card  card-dashboard border">
                <div className="card-header bg-dark">
                    <label className="label py-5 px-5">Plan básico</label>
                </div>
                <div className="card-content">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <ListItem >
                            <ListItemIcon>
                            <CheckCircleIcon className="color-success" />
                            </ListItemIcon>
                            <ListItemText primary="Utiliza nuestra herramienta durante 30 dias por $199" />
                        </ListItem>

                        <ListItem >
                            <ListItemIcon>
                            <CheckCircleIcon className="color-success" />
                            </ListItemIcon>
                            <ListItemText primary="Podrás enviar tu curriculum generado, descargarlo y guardarlo." />
                        </ListItem>

                        <ListItem >
                            <ListItemIcon>
                            <CheckCircleIcon className="color-success" />
                            </ListItemIcon>
                            <ListItemText primary="La membresía es por 30 dias y tendrás la posibilidad de hacer los CV que necesites. Todo quedara guardado en tu cuenta historial." />
                        </ListItem>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {(this.props.pago.checkoutID)?
                        <a target="_blank"  href={"https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id="+this.props.pago.checkoutID} className="button is-link  my-5 is-rounded" >Pagar </a>
                        : null
                        }
                        </Grid>
                </Grid>
                </div>

            </div>


            </>
        )
    }
}

const mapStateToProps = state => ({
    sesion: state.Sesion,
    pago: state.Pago
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_PAGO",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps)( Pagos)