import React from 'react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux";
import EmpresaSubCard from './EmpresaSubCard';
import {URL} from '../../../../Constants/API'

class EmpresaCard  extends React.Component{
     
    handleCreate(e){
      e.preventDefault()
      let empresas = this.props.empresas
      empresas.push({
          curriculumID:   this.props.sesion.curriculumID,
          nombre: "",
          jobs: []
      })
      console.log(this.props.empresas)
      this.props.update(empresas)
      this.forceUpdate()
    }
    componentDidMount(){
      /**Cargo los datos de las empresas */
      let config = { 
        method: "GET", 
        headers:{
            'Content-Type': 'application/json'}
      }
      fetch(URL+"curriculums/empresas/curriculumID/"+this.props.sesion.curriculumID, config)
      .then(res => res.json())
      .then(response =>{
        //En caso de que no haya datos, pongo un valor "vacío"
        console.log("Empresas", response)
       let empresas = [
        { curriculumID:   this.props.sesion.curriculumID,
          nombre: "",
          jobs: []}]
      //---------------------------------------------------//
      
        let rows = (response.code >=400 || response.data.length === 0) ? empresas : response.data 
        this.props.update(rows)  
      })
      .catch(error => console.error(error))
    }
    
    render(){
        return(
            
            <div>
                
                {this.props.empresas.map( (data,index,) =>(

                 <EmpresaSubCard data={data} key={"EmpresaCard-"+index} index={index}/>
                 
                ))}  
               
                <div className="columns has-text-centered">
                  <div className="column is-full">
                    <FontAwesomeIcon className="mr-3 cursor-pointer color-success" size="2x" icon={faPlusCircle} onClick={(e)=>this.handleCreate(e)} />
                  </div>
                </div>
             </div>
        );
        
    }
}
const mapStateToProps = state => ({
  sesion: state.Sesion,
  empresas: state.Empresas
})

const mapDispatchToProps = dispatch => ({
  update(data) {
    dispatch({
      type: "UPDATE_EMPRESAS",
      data
    })
  },
})
export default connect(mapStateToProps,mapDispatchToProps)(EmpresaCard)