import React from 'react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux";
import EstudiosSubCard from "./EstudiosSubCard"
import {URL} from '../../../../Constants/API'
class EstudiosCard  extends React.Component{
    constructor(props){
      super(props)
    }
     handleCreate(e){
        e.preventDefault()
        let estudios = this.props.estudios
        estudios.push({
            curriculumID:   this.props.sesion.curriculumID,
            titulo:         "",
            universidad:    "",
            lugar:          "", 
            fechaInicio:    "2000-12-12",
            fechaFin:       "2000-12-12",
            descripcion:    "",
            proceso:        "Cursando"
        })
        console.log(this.props.estudios)
        this.props.update(estudios)
        this.forceUpdate()
        
    }
    componentDidMount(){
       /**Cargo los datos de los estudios */
      let config = { 
        method: "GET", 
        headers:{
            'Content-Type': 'application/json'}
      }
      fetch(URL+"curriculums/estudios/curriculumID/"+this.props.sesion.curriculumID, config)
      .then(res => res.json())
      .then(response =>{
        console.log("Estudios: ", response)
        //En caso de que no haya datos, pongo un valor "vacío"
        let estudios = [
          {curriculumID:   this.props.sesion.curriculumID,
          titulo:         "",
          universidad:    "",
          lugar:          "",
          fechaInicio:    "2000-12-12",
          fechaFin:       "2000-12-12",
          descripcion:    "",
          proceso:        "Cursando"}]
        //---------------------------------------------------//
        let rows = (response.code <400) ? response.data.rows : estudios
        this.props.update(rows)  
      })
      .catch(error => console.error(error))
    }
    render(){
        return(
            
            <div>
                {this.props.estudios.map( (data,index,) =>(
                     <EstudiosSubCard key={"EstudiosCard-"+index} index={index} data={data}></EstudiosSubCard>
                ))}
                  
               
                  <div className="columns has-text-centered">
                  <div className="column is-full">
                    <FontAwesomeIcon className="mr-3 cursor-pointer color-success" size="2x" icon={faPlusCircle} onClick={(e)=>this.handleCreate(e)} />
                  </div>
                </div>
             </div>
        );
        
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion,
    estudios: state.Estudios
  })

const mapDispatchToProps = dispatch => ({
    update(data) {
      dispatch({
        type: "UPDATE_ESTUDIOS",
        data
      })
    },
  })
export default connect(mapStateToProps,mapDispatchToProps)(EstudiosCard)