import { RestoreRounded } from '@material-ui/icons'
import React from 'react'
import { URL } from '../../../Constants/API'
import { Link, useParams } from 'react-router-dom'
import {useEffect,useState } from 'react'
import Footer from '../Footer'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import '../../../Sass/detalleAviso.scss'
import { FormPostulacion } from './FormPostulacion'
import { Button } from '@mui/material'
import {FacebookShareButton,FacebookIcon,LinkedinShareButton,LinkedinIcon,WhatsappShareButton,WhatsappIcon,FacebookMessengerShareButton,FacebookMessengerIcon} from 'react-share'
function DetalleAviso(){
    const {id} = useParams()
    const [isLoading,setIsLoading] = useState(true)
    const [aviso,setAviso] = useState()
    const shareUrl = window.location.href

    useEffect(()=>{
        console.log('cargando aviso',id)
        fetch(URL+`avisos/id/${id}`)
        .then((response)=>{
            return response.json()
        })
        .then((aviso)=>{
            console.log(aviso)
            setAviso(aviso.data)
            setIsLoading(false)
        })
    },[id])

    return(
        <>
        {/*<section className='hero is-medium'>
        <div className='hero-body'>
            <div className='container'>
                <h4>Ver que poner aca </h4>
            </div>
        </div>
        </section>*/}
        <section className='section'>
            <div className='container'>
            {
                (!isLoading)?
                    <>
                    <span><LocationOnIcon/>{aviso.localidad.nombre}, {aviso.provincia.nombre}</span>
                    <h1>{aviso?.titulo}</h1>
                    <div className='detalles-aviso'>
                        {(aviso.empresa)?
                            <span className='detalle-item'>
                                <span className='detalle-titulo'>Empresa:</span>
                                <span className='detalle-descripcion'>
                                    {aviso.empresa}
                                </span>
                            </span>
                            :null
                        }
                        <span className='detalle-item'>
                            <span className='detalle-titulo'>Tipo de contrato:</span>
                            <span className='detalle-descripcion'>
                            {
                                {
                                'COMPLETO':'Tiempo completo',
                                'PARCIAL':'Tiempo parcial'
                                }[aviso.contrato]
                            }
                            </span>
                        </span>
                        <span className='detalle-item'>
                            <span className='detalle-titulo'>Genero:</span>
                            <span className='detalle-descripcion'>
                                {
                                    {
                                        'M':'Masculino',
                                        'F':'Femenino',
                                        'I':'Indistinto'
                                    }[aviso.genero]
                                }
                            </span>
                        </span>
                        <span className='detalle-item'>
                            <span className='detalle-titulo'>Nivel de educación requerido:</span>
                            <span className='detalle-descripcion'>
                                {
                                    {
                                        'PRIMARIA':'Primario',
                                        'SECUNDARIA':'Secundario',
                                        'UNIVERSIDAD':'Universitario'
                                    }[aviso.educacion]
                                }
                            </span>
                        </span>
                        {(aviso.experiencia)?
                            <span className='detalle-item'>
                                <span className='detalle-titulo'>Experiencia requerida:</span>
                                <span className='detalle-descripcion'>
                                    {
                                        aviso.experiencia
                                    }
                                </span>
                            </span>
                            :null
                        }
                        {(aviso.edad_min)?
                        <span className='detalle-item'>
                            <span className='detalle-titulo'>Edad Minima:</span>
                            <span className='detalle-descripcion'>
                                {
                                    aviso.edad_min
                                }
                            </span>
                        </span>
                        :null
                        }
                        {(aviso.edad_max<100)?
                        <span className='detalle-item'>
                            <span className='detalle-titulo'>Edad Maxima:</span>
                            <span className='detalle-descripcion'>
                                {
                                    aviso.edad_max
                                }
                            </span>
                        </span>
                        :null
                        }
                        {(aviso.vacantes)?
                        <span className='detalle-item'>
                            <span className='detalle-titulo'>Puestos vacantes:</span>
                            <span className='detalle-descripcion'>
                                {
                                    aviso.vacantes
                                }
                            </span>
                        </span>
                        :null
                        }
                    </div>
                    <h3 style={{fontSize:'1.2rem'}}>Compartir este aviso</h3>
                    <div style={{display:'flex', gap:'10px'}}>
                        <FacebookShareButton url={shareUrl} >
                            <FacebookIcon></FacebookIcon>
                        </FacebookShareButton>
                        <FacebookMessengerShareButton url={shareUrl}>
                            <FacebookMessengerIcon></FacebookMessengerIcon>
                        </FacebookMessengerShareButton>
                        <WhatsappShareButton url={shareUrl} title={aviso.title}>
                            <WhatsappIcon></WhatsappIcon>
                        </WhatsappShareButton>
                        <LinkedinShareButton url={shareUrl} title={aviso.title}>
                            <LinkedinIcon></LinkedinIcon>
                        </LinkedinShareButton>
                    </div>
                    <div className='descripcion-aviso'>
                        <h3 className='descripcion-titulo'>Descripción del puesto de trabajo</h3>
                        <span className='descripcion-text'>
                            {aviso.descripcion}
                        </span>
                    </div>
                    <div className='postularme-container'>
                        <Link to={`/postulacion/${id}/${encodeURIComponent(aviso.titulo)}`}>
                            <Button variant="contained" size='large'>¡Postularme!</Button>
                        </Link> 
                    </div>
                    </>

                :<h1>Cargando...</h1>
            }
                
            </div>
        </section>

        <Footer/>
        </>
    )
}

export {DetalleAviso}