import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormPostulacion } from "./FormPostulacion";

function Postulacion(){
    const session = useSelector((state)=>state.Sesion)
    const {avisoID,titulo} = useParams()
    console.log(session)
    return(
        <>
        {/*<section className='hero is-medium'>
        <div className='hero-body'>
            <div className='container'>
                <h4>Ver que poner aca </h4>
            </div>
        </div>
        </section>*/}
        <section className='section'>
            <div className='container'>
                <FormPostulacion personaID={session.personaID} avisoID={avisoID} tituloAviso={decodeURIComponent(titulo)}></FormPostulacion>
            </div>
        </section>
        </>
    )

    
}



export {Postulacion} 