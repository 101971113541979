import React from 'react'
import sinAvisos from '../../../Assets/sin_avisos.png'
import '../../../Sass/avisos.scss'

function ListaVaciaAvisos(){

    return(
        <div className="listado-container">
            <div className='sin-avisos-img-container'>
                <img style={{aspectRadio:'1/1'}} src={sinAvisos}/>
            </div>
        </div>
    )
}

export {ListaVaciaAvisos}