import React from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { connect } from "react-redux";
import { faPenSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'
import {URL} from '../../../../Constants/API'
import Modal from '../../../../Components/Modal'
class LaboralSubCard extends React.Component{
 
    constructor(props) {
        super(props);
        this.state = {
          active: (this.props.data.ID)? false : true ,
          control: false,
          show: false,
          actualidad: !Boolean(this.props.data.fechaFin),
          loader: false,
          laboral: this.props.data,
          provincias: [],
          localidades: [],
          provinciaID: (this.props.data.provinciaID) ? this.props.data.provinciaID : 1
        };
    }
    componentWillReceiveProps(){
        this.setState((state, props) => ({
            laboral: props.data
          }));
    }
    handleClick(e){
        e.preventDefault();
        this.setState({
            active: !this.state.active
        })  
    }
    async handleSave(e,i){
        this.setState({loader:true})
        e.preventDefault()
        await this.formControl()
        if(this.state.control){
            if(this.state.actualidad){
                this.setState({
                    laboral: {...this.state.laboral, fechaFin : null}
                })
            }
            if (this.state.laboral.ID) {
                /**Hago el PUT en caso de que el ID ya exista */
                let config ={
                    method: "PUT", 
                    body: JSON.stringify(this.state.laboral), 
                    headers:{'Content-Type': 'application/json'} }
                fetch(URL + "curriculums/jobs/id/"+this.state.laboral.ID, config )
                .then(res => res.json())
                .then(response => {
                    this.setState({active: false})
                    this.setState({loader:false})
                })
                
            }else{
                /**Hago el post en caso de que el ID no exista */
                console.log("POST")
                let config ={
                    method: "POST", 
                    body: JSON.stringify(this.state.laboral), 
                    headers:{'Content-Type': 'application/json'} }
                fetch(URL + "curriculums/jobs", config )
                .then(res => res.json())
                .then(response => {
                    console.log(response)
                    this.setState({
                        laboral: {...this.state.laboral, ID : response.data.ID }
                    })
                    this.setState({active: false})
                    this.setState({loader:false})
                })
                
            }
            
            
        }else{
            this.setState({show:true})
            this.setState({loader:false})
        }
        
    }
    update(){
        let configGet = { 
            method: "GET", 
            headers:{
                'Content-Type': 'application/json'}}
        fetch(URL+"curriculums/empresas/curriculumID/"+this.props.sesion.curriculumID, configGet)
        .then(res => res.json())
        .then(response =>{
            let rows = (response.code <400) ? response.data : []
            this.props.update(rows)
        })
        .catch(error =>{
            this.setState({loader:false})
            console.error(error)
        }) 

    }
    handleDelete(e){
        this.setState({loader:true})
        e.preventDefault()
        /**Elimino el trabajo */
        let config ={ 
            method: "PATCH", body: JSON.stringify({estado: "BAJA"}), 
            headers:{'Content-Type': 'application/json'}}
        
        /**Si el trabajo está guardado lo elimino del server  */
        if(this.state.laboral.ID){
            
            fetch(URL + "curriculums/jobs/estado/id/"+this.state.laboral.ID, config )
            .then(res => res.json())
            .then(response => {
                console.log(this.state.laboral.ID)
                this.setState({loader:false})
                this.update()  
            })
            .catch(Error => this.setState({loader:false}))
        }else{
            /**Si el laboral no está guardado, actualizo redux */
            this.update()
        }
        

        /**/  
    }
    handleInputChange (e, i) {
        this.setState({
            laboral: {...this.state.laboral,[e.target.name] : e.target.value}
        })
        if(e.target.name==="provinciaID"){
            this.setState({localidades:[
                {
                    "ID": 1,
                    "nombre": "Cargando..."
                }
            ]})
            let config = { 
                method: "GET", 
                headers:{
                    'Content-Type': 'application/json'}
            }
            fetch(URL+"extras/localidades/provinciaID/"+e.target.value, config)
            .then(res => res.json())
            .then(response =>{
                this.setState({localidades: response.data})

            })
            .catch(error => console.error(error))
        }
        
    }
    isEmpty(e){
        if(!e.value){
            e.className = "input is-danger"
            this.setState({control: false})
        }else{
            e.className = "input is-success"
        }
    }
    isEmail(e){
        let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(regex.test(e.value)){
            e.className = "input is-success"
        }else{
            e.className = "input is-danger"
            this.setState({control: false})
        }
    }
    isTel= (e) =>{
        let regex = /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/;
        if(regex.test(e.value)){
            e.className = "input is-success"
        }else{
            e.className = "input is-danger"
            this.setState({control: false})
        }
    }
    handleClassName (){
        let clase = "border   px-5 py-5 mb-5 level experienceSubCard"
        let hidden = "is-hidden"
        let retorno
        if (!this.state.active){
            retorno = clase
        }else{
            retorno = hidden
        }
        return retorno
    }
    
    formControl(){
        this.setState({control: true})
        this.isEmpty(document.getElementById("LaboralSubCard__"+this.props.empresaID+"__puesto--"+this.props.index))
        //this.isEmail(document.getElementById("LaboralSubCard__"+this.props.empresaID+"__email--"+this.props.index))
        //this.isTel(document.getElementById("LaboralSubCard__"+this.props.empresaID+"__tel--"+this.props.index))
       
    }
    handleInputChangeCKEditor(data, i){
        this.setState({
            laboral: {...this.state.laboral, descripcion : data}
        })
       
    } 
    componentDidMount(){
        let config = { 
            method: "GET", 
            headers:{
                'Content-Type': 'application/json'}
        }
        /**Cargo los datos de las provincias */
        this.setState({provincias:[{
            "ID": null,
            "nombre": "Cargando..."}]})
        this.setState({localidades:[{
            "ID": null,
            "nombre": "Cargando..."}]})

        fetch(URL+"extras/provincias", config)
        .then(res => res.json())
        .then(response =>{
            this.setState({provincias: response.data})
            /**Cargo los datos de las localidades*/
            fetch(URL+"extras/localidades/provinciaID/"+this.state.provinciaID, config) //MODIFICAR - VER PROVINCIAID
            .then(res => res.json())
            .then(response =>{
                this.setState({localidades: response.data})

            })
            .catch(error => console.error(error))
        })
        .catch(error => console.error(error))
        
    }
    render(){
        return(
            <>
            <div  className={this.handleClassName()} >
                <div className="level-left">
                    <div className="level-item">
                        <p> {this.state.laboral.titulo} </p>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                         <FontAwesomeIcon className="is-medium edit color-success" icon={faPenSquare} onClick={(e)=>{this.handleClick(e)}} />
                    </div>
                    <div className="level-item">
                        <button className="delete has-text-warning is-medium" aria-label="delete" onClick={(e)=>this.handleDelete(e)} > </button>
                        
                    </div>
                    
                    
                </div>
                
                
            </div>
            <div className={this.state.active ? "mb-5   border py-5 px-5": "is-hidden"} >
            <div className=" mb-5 columns is-multiline">
            <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                Información del puesto
                            </p>
                        </header>
            <div className="card-content columns is-multiline">
                <div className="field column is-full">
                    <label className="label">Puesto de trabajo que ocupaste <span>*</span></label>
                    <div className="control">
                        <input className="input" value={this.state.laboral.titulo} id={"LaboralSubCard__"+this.props.empresaID+"__puesto--"+this.props.index} onChange={(e)=>{this.handleInputChange(e, this.props.index)}} onInput={(e)=>this.isEmpty(e.target)} type="text" name="titulo" placeholder="P. ej.: soldador especializado mantenimiento mecánico"/>
                    </div>
                </div>
                <div className="field column is-full">
                <label className="label"> Tarea mas relevante realizada en la posición</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={this.state.laboral.descripcion}
                        onChange={(event, editor) => {
                            this.handleInputChangeCKEditor(editor.getData(),this.props.index)
                        }}
                        config={{
                            toolbar: {
                                items: [
                                    'bold',
                                    'italic',
                                    'bulletedList',
                                    'numberedList',
                                    'undo',
                                    'redo'
                                ]
                            },
                            placeholder:"Describe brevemente las actividades mas importantes que realizaste en el puesto"
                        }}  
                        
                        />
                </div>
                <div className="field column is-full">
                                <label className="label">Rubro de la empresa<span>*</span></label>
                                <div className="select">
                                    <select id="rubroID" name="rubroID"  onChange={(e)=>{this.handleInputChange(e, this.props.index)}} value={this.state.laboral.rubroID}>
                                        {this.props.rubros.map(element=>(<option value={element.ID}>{element.nombre}</option>))}
                                    </select>
                                </div>
                            </div>
                <div className="field column is-half">
                    <label className="label">Provincia<span>*</span></label>
                    <div className="select">
                        <select id="provinciaID" name="provinciaID"  onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e, this.props.index)} value={this.state.laboral.provinciaID}>
                            {this.state.provincias.map(element=>(<option value={element.ID}>{element.nombre}</option>))}
                        </select>
                    </div>
                </div>
                <div className="field column is-half">
                    <label className="label">Localidad<span>*</span></label>
                    <div className="select">
                        <select id="localidadID" name="localidadID"  onInput={(e)=>this.isEmpty(e.target)} onChange={(e)=>this.handleInputChange(e, this.props.index)} value={this.state.laboral.localidadID}>
                            {this.state.localidades.map(element=>(<option value={element.ID}>{element.nombre}</option>))}
                        </select>
                    </div>
                </div>
               
                
                
                <div className="field column is-one-third">
                    <label className="label">Fecha inicio</label>
                        <div className="control">
                            <input onChange={(e)=>{this.handleInputChange(e, this.props.index)}} value={this.state.laboral.fechaInicio} type="date" name="fechaInicio" />
                        </div>
                </div>
                <div className="field column is-one-third">
                    <label className="label">Fecha fin</label>
                    <div className="control">
                        <input disabled={this.state.actualidad} type="date" onChange={(e)=>{this.handleInputChange(e, this.props.index)}} value={this.state.laboral.fechaFin? this.state.laboral.fechaFin: "2000-01-01"} name="fechaFin"/>
                    </div>
                </div>
                <div className="field column is-one-third">
                    <div className="control pt-5">
                        <label className="checkbox">
                            <input type="checkbox" onChange={(e) =>{this.setState({actualidad: !this.state.actualidad})} } checked={this.state.actualidad}/>
                            Actualidad
                        </label>
                    </div>
                        
                </div>
                            
                
                </div>
                
                </div>
                <div className="card mt-5">
                        <header className="card-header">
                            <p className="card-header-title">
                            Referencias – (Supervisor directo / RRHH / Responsable)   
                            </p>
                        </header>
                    <div className="card-content columns is-multiline">
                    <div className="field column is-full">
                            <label className="label">Sugerencia</label>
                            <p className="pl-1">Ten en cuenta que las referencias laborales concretas, le darán un plus a tu CV.</p>
                    </div>
                    <div className="field column is-full">
                            <label className="label">Nombre y apellido</label>
                            <div className="control">
                                <input className="input" value={this.state.laboral.empleador} onChange={(e)=>{this.handleInputChange(e, this.props.index)}}  type="text" name="empleador" placeholder="P. ej.: Perez Jose  o Contacto de RRHH – Oficina de personal / Etc." />
                            </div>
                        </div>
                        <div className="field column is-full">
                            <label className="label">Teléfono</label>
                            <div className="control">
                                <input className="input"  onInput={(e)=>this.isTel(e.target)} value={this.state.laboral.telefono} id={"LaboralSubCard__"+this.props.empresaID+"__tel--"+this.props.index} onChange={(e)=>{this.handleInputChange(e, this.props.index)}} type="tel" name="telefono" placeholder="P. ej.: 2664000615" />
                            </div>
                        </div>
                        <div className="field column is-full">
                            <label className="label">Email</label>
                            <div className="control">
                                <input className="input" onInput={(e)=>this.isEmail(e.target)} value={this.state.laboral.email} id={"LaboralSubCard__"+this.props.empresaID+"__email--"+this.props.index} onChange={(e)=>{this.handleInputChange(e, this.props.index)}} onChange={(e)=>{this.handleInputChange(e, this.props.index)}} type="email" name="email" placeholder="P. ej.: Contacto@ifelse.com.ar" />
                            </div>
                        </div>
                    </div>
                    <footer className="card-footer py-5 px-5">
                    { (this.state.loader) ?
                        <CircularProgress color="secondary" />
                        :<><button className="button is-success mr-3" onClick={(e)=>this.handleSave(e, this.props.index)} > Guardar</button>
                        <button className="button is-danger" onClick={(e)=>this.handleDelete(e)} >Eliminar </button>
                        </>
                    }
                            
                        </footer>
                    </div>
                
                </div>
               
            </div>
    
            <Modal show={this.state.show} handleClose={()=>this.setState({show: false})}>
                <article className="message is-danger">
                    <div className="message-body ">
                        Error - no se completó la información correctamente
                    </div>
                </article>
            </Modal>
            </>
        )
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion
  })

const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_EMPRESAS",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps)(LaboralSubCard) 