import React from 'react'
import { faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Redirect, withRouter, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from "react-redux"
import Modal from '../../Components/Modal'
import ReactModal from 'react-modal';
import {URL,HOST} from '../../Constants/API'
import DocViewer, { DocViewerRenderers  } from "react-doc-viewer";

const types=
{'application/pdf':'pdf',
'text/plain':'txt',
'application/vnd.openxmlformats-officedocument.wordprocessingml.document':'docx',
'image/jpeg':'jpeg',
'image/jpg':'jpg',
'image/png':'png'}

ReactModal.setAppElement('#root')

class ArchivoCard extends React.Component{
    
    constructor(props) {
        super(props);
        //console.log(props)
        this.state = {
            redirect: false,
            loader: false,
            show: false,
            showPreview: false
          };
    }
    vistaPrevia(){ 
        this.setState({showPreview:true})
    }

    borrarArchivo(archivoID){
        let estado = {estado:"BAJA"}
        console.log(JSON.stringify(estado))
        fetch(URL + "files/estado/id/"+archivoID, { method: "PATCH", body:JSON.stringify(estado), headers:{
            'Content-Type': 'application/json'}})
        .then(res =>res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {    
            this.setState({loader:false})
            console.log(response)    
            if(response.code < 400){
                this.props.handleDelete()
            }
        })
    }

    descargarArchivo(archivoID){
        let url_descarga= URL + "files/download/id/"+archivoID
        window.open(url_descarga)
        /*console.log(URL + "files/download/id/"+archivoID)
        fetch(URL + "files/download/id/"+archivoID, { method: "GET", headers:{
            'Content-Type': 'application/json'}})
        .then(res =>res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {    
            console.log(response)    
            
        })*/
    }

    
    
    render(){
        return(
       <>
       {this.state.redirect ?  <Redirect to='/informacion_personal' /> : null}

       <div className="card card-dashboard  border my-3 ">
            <div className="card-header px-5 py-3 level ">

                <div className="level-left">
                    <div className="level-item">
                        <p>{this.props.title}</p>
                    </div>
                </div>
                <div className="level-right"> 
                    <div className="level-item">
                    { (this.state.loader) ?
                        <CircularProgress color="secondary" />
                        :   <FontAwesomeIcon className="is-medium edit" icon={faDownload} onClick={()=>this.descargarArchivo(this.props.archivoID)} />
                    }
                        
                    </div>
                    <div className="level-item">
                    <div className="level-item">
                         <FontAwesomeIcon className="is-medium edit " onClick={()=>this.vistaPrevia()} icon={faEye}  />
                    </div>
                    <div className="level-item">
                         <FontAwesomeIcon className="is-medium edit " onClick={()=>this.borrarArchivo(this.props.archivoID)} icon={faTrashAlt}  />
                    </div>
                    </div>
                    
                    
                </div>
                
                
            
            </div>
            
       </div>
       <Modal show={this.state.show} handleClose={()=>this.setState({show: false})}>
            <article className="message is-danger">
                <div className="message-body d-flex align-center">
                    Para continuar con el proceso es necesario acceder a la membresía por 30 días.
                    <Link to="/pagos" className="button is-dark is-outlined ml-auto"> Pagar</Link>
                </div>
            </article>
        </Modal>
        {/**Modal de preview*/}
        <ReactModal isOpen={this.state.showPreview}
            parentSelector={() => document.querySelector('#dashboard-container')}
            style={
                {content: {
                    position: 'absolute',
                    top: '40px',
                    left: '40px',
                    right: '40px',
                    bottom: '40px',
                }}
            }
        >
        <div className="modal is-active">
        <div className="modal-background" onClick={()=>{this.setState({showPreview:false})}}></div>
        <div className="modal-content">
        {/*<FileViewer
        fileType={types[this.props.type]}
        filePath={'http://repo.ifelse.com.ar:9001/files/personas/92/files/'+this.props.archivo}
        onError={this.onError}/>*/}
        <DocViewer documents={[{uri:`${HOST}/files/personas/${this.props.sesion.personaID}/files/${this.props.archivo}`}]}
        pluginRenderers={DocViewerRenderers}></DocViewer>
        <button className="modal-close is-large" aria-label="close" onClick={()=>{this.setState({showPreview:false})}}></button>
        </div>
          
        </div>
        </ReactModal>
        {/*<FilePreview show={this.state.showPreview} handleClose={()=>this.setState({showPreview: false})}></FilePreview>*/}
       </>
        )
    }
}
const mapStateToProps = state => ({
    sesion: state.Sesion,
    pago: state.Pago
  })
  
  const mapDispatchToProps = dispatch => ({
    update(data) {
        dispatch({
          type: "UPDATE_SESION",
          data
        })
      },
  })
export default connect(mapStateToProps,mapDispatchToProps) (withRouter(ArchivoCard)) 